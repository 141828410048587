import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';

import * as moment from 'moment';
import * as uuid from 'uuid';

import { Blank } from 'src/app/validators/blank.validator';
import { ErrorValidator } from 'src/app/validators/show-error.validator';
import { environment } from 'src/environments/environment';
import { recuperarPasswordValidation } from 'src/app/modules/login/validators/recuperar-password'
import { ConvertirDatos } from 'src/app/modules/_global/ultil/ConvertirDatos';
import { Rutas } from 'src/app/modules/_global/ultil/rutas';
import * as ItemFormAction from 'src/app/modules/_global/ngrx/actions/itemForm.action';

@Component({
  selector: 'app-recuperar-password',
  templateUrl: './recuperar-password.component.html',
  styleUrls: ['./recuperar-password.component.scss']
})
export class RecuperarPasswordComponent implements OnInit {
  appName = environment.appName;
  formId = 'RecuperarPassword-'+uuid.v4();
  convertirDatos = new ConvertirDatos();
  formGroup: FormGroup = this.fb.group({
    email: ['', [Validators.required ,Blank]]
  });
  fachaActual = moment().format('yyyy');
  respuestaSubscription: Subscription = new Subscription();
  constructor(
    private store: Store<any>,
    private router: Router,
    private fb: FormBuilder,
    private showErrorValidator: ErrorValidator,
    private toastr: ToastrService
    ) { }

  ngOnInit() {
    this.iniciarFormulario();
    this.respuestaSubscription = this.store.select((state) => state?.itemForm?.Respuesta[this.formId]).subscribe(Respuesta => {
      let mensajes = '';
      if(Respuesta != undefined && Respuesta != null){
        if(Respuesta.Messages != undefined && Respuesta.Messages != null){
          mensajes = this.convertirDatos.toastrMensaje(Respuesta.Messages);
        }
        if(Respuesta.Status != undefined && Respuesta.Status != null){
          if(Respuesta.Status === true){
            if(mensajes.length === 0){
              mensajes = "Revisa tu correo, te enviamos una clave temporal, cambiala al ingresar.";
            }
            this.toastr.success(mensajes);
            this.router.navigate([Rutas.LOGIN]);
          }else{
            if(mensajes.length === 0){
              mensajes = "Usuario no está registrado.";
            }
            this.toastr.error(mensajes);
          }
        }
      }
    });
  }

  iniciarFormulario(){
    this.store.dispatch(ItemFormAction.setInciarForm({formId: this.formId}));
    this.formGroup = this.fb.group({
      email: ['', [Validators.required ,Blank]]
    });
  }
  enviarRecuperacionPassowrd(){
    if(this.validarFormulario()){
      const datosGuardar = this.getFormulario();
      this.store.dispatch(ItemFormAction.guardarDatos({
        formId: this.formId,
        modulo: 'recuperar-clave',
        datosGuardar: datosGuardar
      }));
    }
  }
  validarFormulario(){
    if (this.formGroup.invalid){
      this.showErrorValidator.show(this.formGroup.controls, recuperarPasswordValidation);
      return false;
    }else{
        return true;
    }
  }
  getFormulario(): object{
    const formulario = {...this.formGroup.value};
    const datosFormulario = {
      email: formulario ? formulario.email : ''
    };
    return datosFormulario;
  }
}
