import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'text64'
})
export class Text64Characters implements PipeTransform {

  transform(value: any, ...args: unknown[]): string {
    try{
      if(value.length > 30){
        return value.slice(0,27)+'...';
      }else{
        return value;
      }

    }catch(error){
      return value;
    }

  }

}
