import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import * as FileAction from '../actions/file.action';
import { FileService } from '../../service/file/file.service'
import { EffectsBase } from './EffectsBase';

@Injectable()
export class FileEffects {

  moduloListSubscription: Subscription = new Subscription();
  moduloList = [];
  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private fileService: FileService,
    private effectsBase: EffectsBase
  ) {
  }

  GetFileList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FileAction.getFileList),
      exhaustMap(action =>
        this.fileService.getFileList(action.filter).pipe(
          map((resApi: any) => {
            try{
              const respuesta = this.effectsBase.TransformarRespuestaWebApi(resApi);
              this.store.dispatch(FileAction.cleanFileList());
              return FileAction.setFileList({
                fileList: this.effectsBase.GetFileList(respuesta),
                folderList: this.effectsBase.GetFolderList(respuesta)
              });
            }catch(errorRes){
              return FileAction.setError({ error: errorRes });
            }
          }),
          catchError(errorRes => {
            return of(FileAction.setError({ error: errorRes }));
          })
        )
    ))
  );

  RefreshFileList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FileAction.refreshFileList),
      exhaustMap(action =>
        this.fileService.getFileList(action.filter).pipe(
          map((resApi: any) => {
            try{
              const respuesta = this.effectsBase.TransformarRespuestaWebApi(resApi);
              this.store.dispatch(FileAction.cleanFileList());
              return FileAction.setFileList({
                fileList: this.effectsBase.GetFileList(respuesta),
                folderList: this.effectsBase.GetFolderList(respuesta)
              });
            }catch(errorRes){
              return FileAction.setError({ error: errorRes });
            }
          }),
          catchError(errorRes => {
            return of(FileAction.setError({ error: errorRes }));
          })
        )
    ))
  );

}
