import { GlobalModule } from './../_global/global.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './componets/form/form.component';
import { RecuperarPasswordComponent } from './componets/recuperar-password/recuperar-password.component';
@NgModule({
  imports: [
    CommonModule,
    GlobalModule
  ],
  declarations: [FormComponent, RecuperarPasswordComponent],
  entryComponents: [
    FormComponent
  ]
})
export class LoginModule { }
