import { Router } from '@angular/router';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import * as MenuAction from 'src/app/modules/_global/ngrx/actions/menu.action';

@Component({
  selector: 'app-configuracion-sub-menu',
  templateUrl: './configuracion-sub-menu.component.html',
  styleUrls: ['./configuracion-sub-menu.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: window.innerHeight+'px',
        width: '300px',
        opacity: 1
      })),
      state('closed', style({
        height: window.innerHeight+'px',
        width: '100px',
        left: '-350px',
        opacity: 1
      })),
      transition('open => closed', [
        animate('0.25s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ]
})
export class SubMenuHorizontalComponent implements OnInit, OnDestroy{
  empresaSelectedSubscription = new Subscription();
  menuSelectedSubscription = new Subscription();
  subMenuListSubcription = new Subscription();
  loadingSubcription = new Subscription();

  empresaSelected: any = null;
  menuSelected: any = null;

  submenuList = [];
  loading = true;

  showBtnMenu = false;
  showMenuLateral = false;

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    this.validShowMenuLateral();
  }

  constructor(
    private store: Store<any>,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.cleanState();
    this.validShowMenuLateral();
    this.empresaSelectedSubscription = this.store.select((state) => state?.menu?.EmpresaSelected).subscribe(EmpresaSelected => {
      if(EmpresaSelected != undefined && EmpresaSelected != null){
        this.empresaSelected = {...EmpresaSelected};
      }
    });
    this.menuSelectedSubscription = this.store.select((state) => state?.menu?.MenuSelected).subscribe(MenuSelected => {
      if(MenuSelected != undefined && MenuSelected != null){
        this.menuSelected = {...MenuSelected};
      }
    });
    this.subMenuListSubcription = this.store.select((state) => state?.menu?.SubmenuList).subscribe(SubmenuList => {
      this.submenuList = [];
      if(SubmenuList != undefined && SubmenuList != null){
        this.submenuList = [...SubmenuList];
        this.sortSubmoduloList();
      }
    });
    this.loadingSubcription = this.store.select((state) => state?.menu?.LoadingSubmenuList).subscribe(LoadingSubmenuList => {
      if(LoadingSubmenuList != undefined && LoadingSubmenuList != null){
        this.loading = LoadingSubmenuList;
      }
    });
    setTimeout(() => {
      if(this.submenuList.length === 0){
        this.getSubmoduloList();
      }
    }, 1000);
  }

  ngOnDestroy(){
    this.menuSelectedSubscription.unsubscribe();
    this.subMenuListSubcription.unsubscribe();
    this.loadingSubcription.unsubscribe();
    this.cleanState();
  }

  cleanState(){
    this.store.dispatch(MenuAction.cleanSubmenuList());
  }

  sortSubmoduloList(){
    try {
      if(this.submenuList.length > 0){
        if(this.submenuList[0].orden != undefined && this.submenuList[0].orden != null){
          this.submenuList.sort(function(a,b){
            if(a.orden < b.orden){
              return -1;
            }else{
              return 1;
            }
          });
        }
      }
    } catch (error) {
      console.error('Error SubMenuHorizontalComponent -> sortSubmoduloList:', error);
    }
  }

  getSubmoduloList(){
    try {
      this.submenuList = [];
      this.store.dispatch(MenuAction.cleanSubmenuList());
      if(this.menuSelected != undefined && this.menuSelected != null){
        this.store.dispatch(MenuAction.getSubmenuList({
          filter: [
            'filter[where][id_padre]=' + this.menuSelected._id,
            'filter[where][id_empresa]=' + this.empresaSelected._id,
            'filter[where][id_grupo]=' + this.empresaSelected.grupo
          ]
        }));
      }else{
        this.toastr.clear();
        this.toastr.info('Estamos obteniendo menú configuraciones');
        setTimeout(() => {
          if(this.menuSelected != undefined && this.menuSelected != null){
            this.store.dispatch(MenuAction.getSubmenuList({
              filter: [
                'filter[where][id_padre]=' + this.menuSelected._id,
                'filter[where][id_empresa]=' + this.empresaSelected._id,
                'filter[where][id_grupo]=' + this.empresaSelected.grupo
              ]
          }));
          }
        }, 1000);
      }
    } catch (error) {
      console.error('Error MenuModulosComponent -> getSubmoduloList:', error);
    }
  }

  redirectRutaSelected(item){
    try {
      this.store.dispatch(MenuAction.setSubmenuSelected({dato: item}));
      this.showMenuLateral = false;
      if(item != undefined && item != null){
        if(item.ruta != undefined && item.ruta != null){
          this.router.navigate([item.ruta]);
        }
      }
    } catch (error) {
      console.error('Error SubMenuHorizontalComponent -> redirectRutaSelected:', error);
    }
  }

  hideMenuLateral(){
    try {
      this.showMenuLateral = false;
    } catch (error) {
      console.error('Error SubMenuHorizontalComponent -> hideMenuLateral:', error);
    }
  }

  toggleMenuLateral(){
    try {
      this.showMenuLateral = !this.showMenuLateral;
    } catch (error) {
      console.error('Error SubMenuHorizontalComponent -> toggleMenuLateral:', error);
    }
  }

  validShowMenuLateral(){
    try {
      const widthWindows = window.innerWidth;
      if(widthWindows <= 730){
        this.showBtnMenu = true;
      }else{
        this.showBtnMenu = false;
        this.showMenuLateral = false;
      }
    } catch (error) {
      console.error('Error SubMenuHorizontalComponent -> validShowMenuLateral:', error);
    }
  }

}
