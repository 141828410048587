import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ErrorValidator {

  constructor(private toastr: ToastrService) { }

  show(controls: any, validationMessages: any) {
    let messageError = '';
    const messageErrorList = [];
    const nameComponetList = [];
    for (const componet in controls) {
      if (controls[componet].invalid) {
        if (controls[componet].errors) {
          // tslint:disable-next-line: forin
          for (const errorType in controls[componet].errors) {
            try {
              if (validationMessages[componet] && validationMessages[componet][errorType]){
                const message = '<li>' + validationMessages[componet][errorType] + '</li>';
                if (!messageErrorList.includes(message) && !nameComponetList.includes(componet)) {
                  messageError += message;
                  messageErrorList.push(message);
                  nameComponetList.push(componet);
                }
              }
            } catch (error) {
              console.error(error);
            }
          }
        }
      }
    }

    if (messageError === '') {
      this.toastr.error('<ul><li>Se han detectado errores. Por favor revise que los campos requeridos no estén vacíos o con un formato incorrecto.</li></ul>', 'Errores');
    } else {
      this.toastr.error('<ul>' + messageError + '</ul>', 'Errores');
    }
  }

}
