<div class="app-modal-delete">
  <div mat-dialog-title class="titulo-dialog">
    <button [mat-dialog-close]="true" class="close" mat-icon-button><em class="icon ni ni-cross-sm"></em></button>
    <div>
      <span class="icono"><strong><em class="icon ni ni-plus-c"></em></strong></span>
      <span class="titulo">{{data?.titulo ? data?.titulo : 'Desactivar Registro'}}</span>
    </div>
  </div>
  <mat-dialog-content>
    <div class="modal-body modal-body-lg text-center">
      <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-alert-fill bg-danger mb-4"></em>
      <p class="lead">Esta seguro que desea desactivar el registro</p>
      <p *ngIf="data != undefined" >{{data.descripcion ? data.descripcion : ''}}</p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button mat-dialog-close class="btn-primary" (click)="deleteItems()">Desactivar</button>
    <button mat-raised-button mat-dialog-close class="btn-danger">Cancelar</button>
  </mat-dialog-actions>
</div>
