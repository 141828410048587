import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typefile'
})
export class TypeFilePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    try {
      if(value.length > 1){
        return value.split('.').pop();
      }
      return value;
    } catch (error) {
      return value;
    }
  }

}
