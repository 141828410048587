<div class="app-menu-modulos mll-3">
  <div *ngIf="showMenuLateral">
    <button mat-icon-button (click)="toggleMenuLateral()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div *ngIf="loadingMenuList">
    <div class="spinner-border text-primary" role="status"></div>
  </div>
  <div *ngIf="!showMenuLateral">
    <button mat-button class="example-icon" *ngFor="let item of menuList" (click)="redirectRutaSelected(item)">
      {{ item?.nombre ?  item?.nombre : ''}}
    </button>
  </div>
  <div *ngIf="showMenuLateral">
    <div class="card no-padding menu-flotante-izq" [@openClose]="isOpen ? 'open' : 'closed'">
      <div class="card-header bg-primary">
        <a class="btn btn-group-refresh text-white" (click)="hideMenuLateral()">
          <mat-icon class="mr-1">arrow_back_ios</mat-icon> <span>Ocultar</span>
        </a>
      </div>
      <div class="card-body">
        <ul class="nav flex-column" *ngIf="menuList.length > 0">
          <li class="nav-item btn text-left" *ngFor="let item of menuList">
            <a class="nav-link" (click)="redirectRutaSelected(item)">
              <span class="nk-menu-text">{{ item?.nombre ?  item?.nombre : ''}}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
