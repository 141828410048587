import { AbstractControl } from '@angular/forms';

export function Blank(control: AbstractControl) {
  try{
    const valorElemento: string = control.value;
    if (valorElemento != undefined && valorElemento != null) {
      if(valorElemento.trim().length <= 0){
        return { blank: true };
      }
    }
  }
  catch(error){
    console.error('Error al validar campo en blanco', error);
  }

  return null;
}
