<div class="app-footer text-center">
  <footer class="bg-primary text-center text-lg-start">
    <div class="text-center p-3">
      &copy; {{ fechaActual | date: "yyyy" }}
      <span class="text-white">GEMSA 360 </span>
      <a class="text-white" href="https://servicioplanet.com/" target="_blank">
        coding with love: Servicioplanet.com</a
      >
    </div>
  </footer>
</div>
