import { Store } from '@ngrx/store';
import { EffectsBase } from './EffectsBase';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, exhaustMap, map, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ItemFormAction from 'src/app/modules/_global/ngrx/actions/itemForm.action';
import * as LoginAction from 'src/app/modules/_global/ngrx/actions/login.action';
import { CrudBasicService } from '../../service/crud-basic/crudBasic.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ItemFormEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private crudBasicService: CrudBasicService,
    private toastr: ToastrService,
    private effectsBase: EffectsBase
  ) {}

  GetDatos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemFormAction.getDatos),
      exhaustMap((action) =>
        this.crudBasicService.getDatos(action.modulo, action.filtros).pipe(
          map((resApi: any) => {
            try {
              const res = this.effectsBase.TransformarRespuestaWebApi(resApi);
              this.store.dispatch(ItemFormAction.clearDatos({ formId: action.formId }));
              return ItemFormAction.setDatos({
                formId: action.formId,
                datosForm: res.Data
              });
            }catch (exerror){
              this.store.dispatch(ItemFormAction.clearDatos({ formId: action.formId }));
              this.store.dispatch(ItemFormAction.setError({ formId: action.formId, error: exerror }));
            }
          }),
          catchError(errorRes => {
            this.store.dispatch(ItemFormAction.clearDatos({ formId: action.formId }));
            return of(ItemFormAction.setError({ formId: action.formId, error: errorRes }));
          })
        )
    ))
  );

  GetEmpresas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemFormAction.getEmpresas),
      exhaustMap((action) =>
        this.crudBasicService.getDatos(action.modulo, action.filtros).pipe(
          map((resApi: any) => {
            try {
              const res = this.effectsBase.TransformarRespuestaWebApi(resApi);
              return ItemFormAction.setEmpresas({
                formId: action.formId,
                datos: res.Data
              });
            }catch (exerror){
              return ItemFormAction.setError({ formId: action.formId, error: exerror});
            }
          }),
          catchError(errorRes => {
            return of(ItemFormAction.setError({ formId: action.formId, error: errorRes }));
          })
        )
    ))
  );

  GetGrupos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemFormAction.getGrupos),
      exhaustMap((action) =>
        this.crudBasicService.getDatos(action.modulo, action.filtros).pipe(
          map((resApi: any) => {
            try {
              const res = this.effectsBase.TransformarRespuestaWebApi(resApi);
              return ItemFormAction.setGrupos({
                formId: action.formId,
                datos: res.Data
              });
            }catch (exerror){
              return ItemFormAction.setError({ formId: action.formId, error: exerror });
            }
          }),
          catchError(errorRes => {
            return of(ItemFormAction.setError({ formId: action.formId, error: errorRes }));
          })
        )
    ))
  );

  GetEmpresasGruposByUsuario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemFormAction.getEmpresasGruposByUsuario),
      exhaustMap((action) =>
        this.crudBasicService.postDatos(action.modulo, action.filtros).pipe(
          map((resApi: any) => {
            try {
              const res = this.effectsBase.TransformarRespuestaWebApi(resApi);
              return ItemFormAction.setEmpresasGruposByUsuario({
                formId: action.formId,
                datos: res.Data
              });
            }catch (exerror){
              this.store.dispatch(ItemFormAction.setError({ formId: action.formId, error: exerror }));
            }
          }),
          catchError(errorRes => {
            return of(ItemFormAction.setError({ formId: action.formId, error: errorRes }));
          })
        )
    ))
  );

  GetAllModulos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemFormAction.getAllModulo),
      exhaustMap((action) =>
        this.crudBasicService.getDatos('modulos', ['filter[where][factory]=true']).pipe(
          map((resApi: any) => {
            try {
              const res = this.effectsBase.TransformarRespuestaWebApi(resApi);
              return ItemFormAction.setAllModulo({
                formId: action.formId,
                moduloList: res.Data
              });
            }catch (exerror){
              this.store.dispatch(ItemFormAction.setError({ formId: action.formId, error: exerror }));
            }
          }),
          catchError(errorRes => {
            return of(ItemFormAction.setError({ formId: action.formId, error: errorRes }));
          })
        )
    ))
  );

  GetSubModuloByModulo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemFormAction.getSubModuloByModulo),
      exhaustMap((action) =>
        this.crudBasicService.getDatos('modulos', ['filter[where][id_padre]=' + action.id_modulo]).pipe(
          map((resApi: any) => {
            try {
              const res = this.effectsBase.TransformarRespuestaWebApi(resApi);
              return ItemFormAction.setSubModuloByModulo({
                formId: action.formId,
                subModuloList: res.Data
              });
            }catch (exerror){
              this.store.dispatch(ItemFormAction.setError({ formId: action.formId, error: exerror }));
            }
          }),
          catchError(errorRes => {
            return of(ItemFormAction.setError({ formId: action.formId, error: errorRes }));
          })
        )
    ))
  );

  guardarDatos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemFormAction.guardarDatos),
      exhaustMap(action =>
        this.crudBasicService.postDatos(action.modulo, action.datosGuardar).pipe(
          map((resApi: any) => {
            try{
              const res = this.effectsBase.TransformarRespuestaWebApi(resApi);
              this.store.dispatch(ItemFormAction.clearRespuesta({formId: action.formId}));
              return ItemFormAction.setRespuesta({
                formId: action.formId,
                code: res.Code,
                data: res.Data,
                messages: res.Messages,
                status: res.Status
              });
            }catch (exerror){
              this.store.dispatch(ItemFormAction.setError({ formId: action.formId, error: exerror }));
            }
          }),
          catchError(errorRes => {
            return of(ItemFormAction.setError({ formId: action.formId, error: errorRes }));
          })
        )
    ))
  );

  deleteDatos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemFormAction.deleteDatos),
      exhaustMap(action =>
        this.crudBasicService.deleteDatos(action.modulo, action.filtros).pipe(
          map((resApi: any) => {
            try{
              const res = this.effectsBase.TransformarRespuestaWebApi(resApi);
              this.store.dispatch(ItemFormAction.clearRespuesta({formId: action.formId}));
              return ItemFormAction.setRespuesta({
                formId: action.formId,
                code: res.Code,
                data: res.Data,
                messages: res.Messages,
                status: res.Status
              });
            }catch (exerror){
              this.store.dispatch(ItemFormAction.setError({ formId: action.formId, error: exerror }));
            }
          }),
          catchError(errorRes => {
            return of(ItemFormAction.setError({ formId: action.formId, error: errorRes }));
          })
        )
    ))
  );

  uploadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemFormAction.uploadFile),
      exhaustMap(action =>
        this.crudBasicService.postDatos(action.modulo, action.datosGuardar).pipe(
          map((resApi: any) => {
            try{
              const res = this.effectsBase.TransformarRespuestaWebApi(resApi);
              this.store.dispatch(ItemFormAction.clearRespuesta({formId: action.formId}));
              const archivo = res.Data[0];
              this.store.dispatch(ItemFormAction.guardarDatos({
                formId: action.formId,
                modulo: 'archivos',
                datosGuardar: {
                  'icono': this.effectsBase.getImageByTypeFile(archivo.file_name.split('.').pop()),
                  'etiqueta': '',
                  'tipo': 'archivo',
                  'ruta': archivo.ruta,
                  "id_padre": archivo.id_modulo,
                  "nombre": archivo.file_name
                }
              }));
              return ItemFormAction.setRespuesta({
                formId: action.formId,
                code: res.Code,
                data: res.Data,
                messages: res.Messages,
                status: res.Status
              });
            }catch (exerror){
              this.store.dispatch(ItemFormAction.setError({ formId: action.formId, error: exerror }));
            }
          }),
          catchError(errorRes => {
            return of(ItemFormAction.setError({ formId: action.formId, error: errorRes }));
          })
        )
    ))
  );

  actualizarDatos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemFormAction.actulizarDatos),
      exhaustMap(action =>
        this.crudBasicService.putDatos(action.modulo, action.datosGuardar, action.filtros).pipe(
          map((resApi: any) => {
            try{
              const res = this.effectsBase.TransformarRespuestaWebApi(resApi);
              this.store.dispatch(ItemFormAction.clearRespuesta({formId: action.formId}));
              return ItemFormAction.setRespuesta({
                formId: action.formId,
                code: res.Code,
                data: res.Data,
                messages: res.Messages,
                status: res.Status
              });
            }catch (exerror){
              this.store.dispatch(ItemFormAction.setError({ formId: action.formId, error: exerror}));
            }
          }),
          catchError(errorRes => {
            return of(ItemFormAction.setError({ formId: action.formId, error: errorRes }));
          })
        )
    ))
  );

  setRefrescarLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemFormAction.setRefrescarLogin),
      exhaustMap(action =>
        this.crudBasicService.refrescarLogin(action.modulo, action.datosGuardar).pipe(
          map((resApi: any) => {
            try{
              const respuesta = this.effectsBase.TransformarRespuestaWebApi(resApi);
              this.store.dispatch(ItemFormAction.setRespuesta({
                formId: action.formId,
                code: respuesta.Code,
                data: respuesta.Data,
                messages: respuesta.Messages,
                status: respuesta.Status
              }));
              return LoginAction.setStore({ respuesta: respuesta});
            }catch (exerror){
              this.store.dispatch(ItemFormAction.setError({ formId: action.formId, error: exerror }));
            }
          }),
          catchError(errorRes => {
            return of(ItemFormAction.setError({ formId: action.formId, error: errorRes }));
          })
        )
    ))
  );
}
