<div class="app-login-form">
<!-- content @s -->
<div class="nk-split nk-split-page nk-split-md">
  <div class="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white">
      <div class="absolute-top-right d-lg-none p-3 p-sm-5">
          <a href="#" class="toggle btn-white btn btn-icon btn-light" data-target="athPromo"><em class="icon ni ni-info"></em></a>
      </div>
      <div class="nk-block nk-block-middle nk-auth-body">
        <div class="col-12">
          <div class="brand-logo mb-3 text-center">
            <a><img class="logo-dark" src="./assets/images/logo-blanco.png" alt="{{ appName }}" height="100"></a>
        </div>
        </div>
          <div class="nk-block-head">
              <div class="nk-block-head-content">
                  <h5 class="nk-block-title">Inicio de Sesión</h5>
                  <div class="nk-block-des">
                      <p>Acceda a Portal Financiero con su identificación y contraseña.</p>
                  </div>
              </div>
          </div><!-- .nk-block-head -->
          <form [formGroup]="formGroup" (ngSubmit)="submitForm()">
              <div class="form-group">
                  <div class="form-label-group">
                      <label class="form-label" for="default-01">Usuario</label>
                  </div>
                  <input type="text" class="form-control form-control-lg" id="default-01"
                    placeholder="Ingrese su identificación"
                    formControlName="usuario" name="usuario"
                    required cdkFocusInitial>
              </div><!-- .foem-group -->
              <div class="form-group">
                  <div class="form-label-group">
                      <label class="form-label" for="password">Contraseña</label>
                      <a href="login/recuperarpaswword" class="link link-primary link-sm" tabindex="-1">Olvido su contraseña?</a>
                  </div>
                  <div class="form-control-wrap">
                      <a tabindex="-1" href="#" class="form-icon form-icon-right passcode-switch" data-target="password">
                          <em class="passcode-icon icon-show icon ni ni-eye"></em>
                          <em class="passcode-icon icon-hide icon ni ni-eye-off"></em>
                      </a>
                      <input type="password" class="form-control form-control-lg" id="password"
                        placeholder="Ingrese su contraseña"
                        formControlName="clave" name="pasclavesword"
                        required>
                  </div>
              </div><!-- .foem-group -->
              <div class="form-group">
                  <button class="btn btn-lg btn-primary btn-block">Ingresar</button>
              </div>
          </form><!-- form -->
      </div><!-- .nk-block -->
      <div class="nk-block nk-block-middle nk-auth-body text-center">
        <p>Al ingresar a Portal Financiero, estás aceptando nuestros</p>
        <p>
          <strong><a href="https://gemsa360-app.s3-us-west-2.amazonaws.com/condiciones/Gemsa360.pdf" target="_blank">Términos y Condiciones.</a></strong>
        </p>
      </div>
      <div class="nk-block nk-auth-footer text-center">
          <div class="mt-3">
              <p>&copy; {{ currentDate }} {{ appName }}. Todos los derechos reservados.</p>
          </div>
      </div><!-- .nk-block -->
  </div><!-- .nk-split-content -->
  <div class="nk-split-content nk-split-stretch bg-lighter d-flex toggle-break-lg toggle-slide toggle-slide-right" data-content="athPromo" data-toggle-screen="lg" data-toggle-overlay="true">
      <div class="slider-wrap w-100 w-max-550px p-3 p-sm-5 m-auto">
          <div class="slider-init" data-slick='{"dots":true, "arrows":false}'>
              <div class="slider-item">
                  <div class="nk-feature nk-feature-center">
                      <div class="nk-feature-img">
                          <img class="round" src="./assets/images/slides/promo-a.png" srcset="./assets/images/slides/promo-a2x.png 2x" alt="">
                      </div>
                      <div class="nk-feature-content py-4 p-sm-5">
                          <h4>{{ appName }}</h4>
                          <p>.</p>
                      </div>
                  </div>
              </div><!-- .slider-item -->
          </div><!-- .slider-init -->
          <div class="slider-dots"></div>
          <div class="slider-arrows"></div>
      </div><!-- .slider-wrap -->
  </div><!-- .nk-split-content -->
</div><!-- .nk-split -->
</div><!--FIN app-login-form -->

