import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import * as uuid from 'uuid';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ACCIONES_FORMULARIO } from 'src/app/modules/_global/ultil/accionesFormulario';
import { ErrorValidator } from 'src/app/validators/show-error.validator';
import { Blank } from 'src/app/validators/blank.validator';
import * as ItemFormAction from 'src/app/modules/_global/ngrx/actions/itemForm.action';
import * as DataGridAction from 'src/app/modules/_global/ngrx/actions/dataGrid.action';
import { formValidation } from '../../validators/form.validatos';
import { ConvertirDatos } from 'src/app/modules/_global/ultil/ConvertirDatos';
import { paisList } from 'src/app/modules/_global/ultil/paisList';
import { FileService } from 'src/app/modules/_global/service/file/file.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ValidacionesUtil } from 'src/app/modules/_global/ultil/ValidacionesUtil';
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {

  formId = 'Empresa-'+uuid.v4();
  formIdLogo = 'EmpresaLogo-'+uuid.v4();
  convertirDatos = new ConvertirDatos();
  nombreModulo: string;
  formGroup: FormGroup = this.fb.group({
    ruc:  ['', [Validators.required, Blank]],
    razon_social:  ['', [Validators.required, Blank]],
    direccion: '',
    logo: '',
    representante:  ['', [Validators.required, Blank]],
    telefono: '',
    sitio_web: '',
    descripcion: '',
    fecha_licencia: moment(),
    activo: true,
    latitud: '',
    longitud: '',
    pais: ['Ecuador', [Validators.required]],
    tipo_identificacion: ['', [Validators.required]],
    raet: ['', [Validators.required, Blank]],
    expediente: ['', [Validators.required, Blank]],
    representante_pais: ['Ecuador', [Validators.required]],
    representante_tipo_identificacion: ['', [Validators.required]],
    representante_identificacion: ['', [Validators.required, Blank]],
    fecha_inicio_operaciones: ['', [Validators.required]],
    email_empresarial: ''
  });
  ACCIONES_FORMULARIO = ACCIONES_FORMULARIO;
  paisList = paisList;

  panelOpenState = false;

  permisosSubscription: Subscription = new Subscription();
  formularioSubscription: Subscription = new Subscription();
  respuestaSubscription: Subscription = new Subscription();
  respuestaLogoSubscription: Subscription = new Subscription();
  loginSubscription: Subscription = new Subscription();
  loginState: any;
  token: any;

  datosFormulario: any;

  empresaSelected: string;
  grupoSelected: string;

  accionActual = '';

  urlLogo: any;
  urkLogoHttp = null;
  fileLogo: any;

  disabledBtn = false;
  btnImgClick = false;

  permisosModule: any = null;
  permisoCreate = true;
  permisoDelete = true;
  permisoDownload = true;
  permisoUpdate = true;
  permisoUpload = true;

  constructor(
    private store: Store<any>,
    public dialogRef: MatDialogRef<FormComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private showErrorValidator: ErrorValidator,
    private toastr: ToastrService,
    private uploadService: FileService,
    private validacionesUtil: ValidacionesUtil
  ) { }

  ngOnInit() {
    this.disabledBtn = false;
    this.nombreModulo = 'empresas';
    this.store.dispatch(ItemFormAction.setInciarForm({formId: this.formId}));
    this.iniciarFormulario();

    this.permisosSubscription = this.store.select((state) => state?.menu?.SubmenuSelected).subscribe(SubmenuSelected => {
      if(SubmenuSelected != undefined && SubmenuSelected != null){
        if(SubmenuSelected.permisos != undefined ||SubmenuSelected.permisos!= null){
          this.permisosModule = {...SubmenuSelected.permisos};
          this.setPermisosModule();
        }
      }
    });

    this.loginSubscription = this.store.select((state) => state?.login?.Respuesta).subscribe((Respuesta) => {
      if(Respuesta != undefined){
        this.loginState = {...Respuesta};
        if(this.loginState.Data != undefined){
          if(this.loginState.Data.token != undefined){
            //Obtengo el token del usuario que inicio sessión
            this.token = this.loginState.Data.token;
          }
        }
      }
    });
    this.formularioSubscription = this.store.select((state) => state?.itemForm?.Formulario[this.formId]).subscribe(formulario => {
      if(formulario){
        if(formulario.datosForm){
          this.datosFormulario = {...formulario.datosForm};
          this.setDatosFormulario();
        }
      }
    });
    this.respuestaSubscription = this.store.select((state) => state?.itemForm?.Respuesta[this.formId]).subscribe(Respuesta => {
      let mensajes = '';
      if(Respuesta != undefined && Respuesta != null){
        if(Respuesta.Messages != undefined && Respuesta.Messages != null){
          mensajes = this.convertirDatos.toastrMensaje(Respuesta.Messages);
        }
        if(Respuesta.Status != undefined && Respuesta.Status != null){
          if(Respuesta.Status === true){
            if(Respuesta.Data != undefined){
              const respuesta = Respuesta.Data;
              if(respuesta[0] != undefined){
                this.guardarImagenLogo(respuesta[0]._id);
              }
            }
            if(mensajes.length === 0){
              mensajes = "Se guardo el registro correctamente.";
            }
            this.toastr.success(mensajes);
            this.closeModal();
          }else{
            if(mensajes.length === 0){
              mensajes = "No se puedo guardar el registro correctamente.";
            }
            this.toastr.error(mensajes);
          }
        }
      }
      this.disabledBtn = false;
    });
    this.respuestaLogoSubscription = this.store.select((state) => state?.itemForm?.Respuesta[this.formIdLogo]).subscribe(Respuesta => {
      let mensajes = '';
      if(Respuesta != undefined && Respuesta != null){
        if(Respuesta.Messages != undefined && Respuesta.Messages != null){
          mensajes = this.convertirDatos.toastrMensaje(Respuesta.Messages);
        }
        if(Respuesta.Status != undefined && Respuesta.Status != null){
          if(Respuesta.Status === true){
            if(mensajes.length === 0){
              mensajes = "Se guardo el logo correctamente.";
            }
            this.toastr.success(mensajes);
          }else{
            if(mensajes.length === 0){
              mensajes = "No se puedo guardar el logo.";
            }
            this.toastr.error(mensajes);
          }
        }
      }
      this.disabledBtn = false;
    });
  }

  ngOnDestroy() {
    this.loginSubscription.unsubscribe();
    this.formularioSubscription.unsubscribe();
    this.respuestaSubscription.unsubscribe();
    this.respuestaLogoSubscription.unsubscribe();
    this.store.dispatch(ItemFormAction.clearByFormId({formId: this.formId}));
    this.store.dispatch(ItemFormAction.clearByFormId({formId: this.formIdLogo}));
    this.store.dispatch(DataGridAction.getDatos({ modulo: this.nombreModulo }));
  }

  iniciarFormulario(){
    this.formGroup = this.fb.group({
      ruc:  ['', [Validators.required, Blank]],
      razon_social:  ['', [Validators.required, Blank]],
      direccion: '',
      logo: '',
      representante:  ['', [Validators.required, Blank]],
      telefono: '',
      sitio_web: '',
      descripcion: '',
      fecha_licencia: moment(),
      activo: true,
      latitud: '',
      longitud: '',
      pais: ['Ecuador', [Validators.required]],
      tipo_identificacion: ['', [Validators.required]],
      raet: ['', [Validators.required, Blank]],
      expediente: ['', [Validators.required, Blank]],
      representante_pais: ['Ecuador', [Validators.required]],
      representante_tipo_identificacion: ['', [Validators.required]],
      representante_identificacion: ['', [Validators.required, Blank]],
      fecha_inicio_operaciones: ['', [Validators.required]],
      email_empresarial: ''
    });

    try {
      if(this.paisList.length > 0){
        const valorInicial = this.paisList.find(elemento => elemento.pais === 'Ecuador');
        this.formGroup.patchValue({
          pais: valorInicial.pais,
          tipo_identificacion: valorInicial.tipo,
          representante_pais: valorInicial.pais,
          representante_tipo_identificacion: valorInicial.tipo
        });
      }

      if (this.data.accion === ACCIONES_FORMULARIO.VER){
        this.formGroup.disable();
      }else{
        this.formGroup.enable();
      }

      if(this.data.accion === ACCIONES_FORMULARIO.VER){
        if(this.data.id != undefined && this.data.id != null){
          this.toastr.clear();
          this.toastr.info('Estamos obteniendo su información.');
           this.getDatosFormulario();
        }
      }else if(this.data.accion === ACCIONES_FORMULARIO.EDITAR){
        this.setDatosFormulario();
      }

    } catch (error) {
      console.error('Error FormComponent iniciarFormulario',error);
    }
  }

  setPermisosModule(){
    try {
      if(this.permisosModule != undefined && this.permisosModule != null){
        if(this.permisosModule.create != undefined && this.permisosModule.create != null){
          this.permisoCreate = this.permisosModule.create;
        }
        if(this.permisosModule.delete != undefined && this.permisosModule.delete != null){
          this.permisoDelete = this.permisosModule.delete;
        }
        if(this.permisosModule.update != undefined && this.permisosModule.update != null){
          this.permisoUpdate = this.permisosModule.update;
        }
      }
    } catch (error) {
      console.error('Error usuario index setPermisosModule', error);
    }
  }

  getDatosFormulario(){
    try {
      this.store.dispatch(ItemFormAction.getDatos({
        modulo: this.nombreModulo,
        formId: this.formId,
        filtros: ['filter[where][_id]='+this.data.id]
      }));
    } catch (error) {
      console.error('Error FormComponent getDatosFormulario',error);
    }
  }

  setDatosFormulario(){
    try {
      this.formGroup.patchValue({
        ruc:   this.datosFormulario ? this.datosFormulario[0].ruc : '',
        razon_social:   this.datosFormulario ? this.datosFormulario[0].razon_social : '',
        direccion:  this.datosFormulario ? this.datosFormulario[0].direccion : '',
        representante:   this.datosFormulario ? this.datosFormulario[0].representante : '',
        telefono:  this.datosFormulario ? this.datosFormulario[0].telefono : '',
        sitio_web:  this.datosFormulario ? this.datosFormulario[0].sitio_web : '',
        descripcion:  this.datosFormulario ? this.datosFormulario[0].descripcion : '',
        fecha_licencia: this.datosFormulario ? this.datosFormulario[0].fecha_licencia : moment(),
        activo:  this.datosFormulario ? this.datosFormulario[0].activo : true,
        latitud: this.datosFormulario ? this.datosFormulario[0].latitud : '',
        longitud: this.datosFormulario ? this.datosFormulario[0].longitud : '',
        pais: this.datosFormulario ? this.datosFormulario[0].pais : '',
        tipo_identificacion: this.datosFormulario ? this.datosFormulario[0].tipo_identificacion : '',
        raet: this.datosFormulario ? this.datosFormulario[0].raet : '',
        expediente: this.datosFormulario ? this.datosFormulario[0].expediente : '',
        representante_pais: this.datosFormulario ? this.datosFormulario[0].representante_pais : '',
        representante_tipo_identificacion: this.datosFormulario ? this.datosFormulario[0].representante_tipo_identificacion : '',
        representante_identificacion: this.datosFormulario ? this.datosFormulario[0].representante_identificacion : '',
        fecha_inicio_operaciones: this.datosFormulario ? this.datosFormulario[0].fecha_inicio_operaciones : '',
        email_empresarial: this.datosFormulario ? this.datosFormulario[0].email_empresarial : ''
      });
      this.formGroup.patchValue({
        logo: this.datosFormulario ? this.datosFormulario[0].logo : ''
      });
      if(this.formGroup.value.logo != undefined && this.formGroup.value.logo != null){
        this.urkLogoHttp = this.formGroup.value.logo;
      }
    } catch (error) {
      console.error('Error FormComponent setDatosFormulario',error);
    }
  }

  guardarFormulario(){
    try {
      if(this.validarFormulario()){
        const datosGuardar = this.getFormulario();
        this.disabledBtn = true;
        if(this.data.accion == ACCIONES_FORMULARIO.CREAR){
          this.store.dispatch(ItemFormAction.guardarDatos({
            modulo: this.nombreModulo,
            formId: this.formId,
            datosGuardar: datosGuardar
          }));
        }else{
          datosGuardar['_id'] = this.data.id;
          this.store.dispatch(ItemFormAction.actulizarDatos({
            modulo: this.nombreModulo,
            formId: this.formId,
            datosGuardar: datosGuardar,
            filtros: null
          }));
        }
      }
    } catch (error) {
      this.disabledBtn = false;
      this.toastr.error('No se pudo guardar el archivo.');
    }

  }

  validarFormulario(){
    if (this.formGroup.invalid){
      this.showErrorValidator.show(this.formGroup.controls, formValidation);
      //ya borroe los pasos 15-01-2021
      return false;
    }
    const tipo_documento_empresa = this.formGroup.value.tipo_identificacion;
    if(tipo_documento_empresa == 'RUC'){
      const ruc = this.formGroup.value.ruc;
      if(!this.validacionesUtil.verificarRuc(ruc)){
        this.toastr.error('<strong>Num. Identificación</strong> de la empresa no es valido');
        return false;
      }
    }
    const representante_tipo_identificacion = this.formGroup.value.representante_tipo_identificacion;
    if(representante_tipo_identificacion == 'CEDULA'){
      const cedula = this.formGroup.value.representante_identificacion;
      if(!this.validacionesUtil.verificarCedula(cedula)){
        this.toastr.error('<strong>Num. Identificación</strong> del representante legal no es valido');
        return false;
      }
    }
    return true;
  }

  getFormulario(): object{
    const formulario = {...this.formGroup.value};
    const datosFormulario = {
      ruc: formulario ? formulario.ruc : '',
      razon_social: formulario ? formulario.razon_social : '',
      direccion: formulario ? formulario.direccion : '',
      logo: this.datosFormulario ? this.datosFormulario[0].logo : '',
      representante: formulario ? formulario.representante : '',
      telefono: formulario ? formulario.telefono : '',
      sitio_web: formulario ? formulario.sitio_web : '',
      descripcion: formulario ? formulario.descripcion : '',
      fecha_licencia: this.datosFormulario ? this.datosFormulario[0].fecha_licencia : moment(),
      activo: this.datosFormulario ? this.datosFormulario[0].activo : true,
      latitud: this.datosFormulario ? this.datosFormulario[0].latitud : '',
      longitud: this.datosFormulario ? this.datosFormulario[0].longitud : '',
      pais: formulario ? formulario.pais : '',
      tipo_identificacion: formulario ? formulario.tipo_identificacion : '',
      raet: formulario ? formulario.raet : '',
      expediente: formulario ? formulario.expediente : '',
      representante_pais: formulario ? formulario.representante_pais : '',
      representante_tipo_identificacion: formulario ? formulario.representante_tipo_identificacion : '',
      representante_identificacion: formulario ? formulario.representante_identificacion : '',
      fecha_inicio_operaciones: formulario ? formulario.fecha_inicio_operaciones : '',
      email_empresarial: formulario ? formulario.email_empresarial : ''
    };
    return datosFormulario;
  }

  async guardarImagenLogo(id_empresa){
    try {
      if(id_empresa != undefined && id_empresa != null && this.fileLogo != undefined && this.fileLogo != null){
        const formData = new FormData();
        formData.append('file', this.fileLogo);
        formData.append('id_empresa', '' + id_empresa);
        formData.append('id_modulo', 'logo');
        await this.uploadService.uploadFile(formData).subscribe(
          event => {
            if (event.type === HttpEventType.UploadProgress) {

            } else if (event instanceof HttpResponse) {
              const bodyResponse  = event.body;
              if(bodyResponse.Status){
                this.store.dispatch(ItemFormAction.actulizarDatos({
                  modulo: this.nombreModulo,
                  formId: this.formIdLogo,
                  datosGuardar: {
                    '_id': id_empresa,
                    'logo': bodyResponse.Data[0].ruta
                  },
                  filtros: null
                }));
              }
            }
          });
      }
    } catch (error) {
      console.error('Error empresas -> guardarImagenLogo', error);
    }
  }

  showEditarFormulario(){
    try{
      this.disabledBtn = false;
      this.data.accion  = ACCIONES_FORMULARIO.EDITAR;
      this.iniciarFormulario();
    }catch(error){
      console.error('Error empresas -> showEditarFormulario', error);
    }
  }

  changePaisSelected(event){
    try {
      const paisSelected = event.value ? event.value : null;
      const pais = this.paisList.find(elemento => elemento.pais === paisSelected);
      if(pais != null && pais != undefined){
        this.formGroup.patchValue({
         tipo_identificacion : pais.tipo
        });
      }
    } catch (error) {
      console.error('Error empresas -> changePaisSelected', error);
    }
  }

  changeRepresentantePaisSelected(event){
    try {
      const paisSelected = event.value ? event.value : null;
      const pais = this.paisList.find(elemento => elemento.pais === paisSelected);
      if(pais != null && pais != undefined){
        this.formGroup.patchValue({
          representante_tipo_identificacion : pais.tipo
        });
      }
    } catch (error) {
      console.error('Error empresas -> changeRepresentantePaisSelected', error);
    }
  }

  fileChangeEvent(files){
    this.btnImgClick = true;
    this.urkLogoHttp = null;
    const fileList = [...files];
    try {
      if (fileList && fileList[0]) {
        if(fileList[0].name != undefined){
          if(this.ValidateTypeFile(fileList[0].name)){
            this.fileLogo = fileList[0];
            var reader = new FileReader();
            reader.onload = (event:any) => {
              this.urlLogo = event.target.result;
            }
            reader.readAsDataURL(fileList[0]);
          }
        }
      }
    } catch (error) {
      console.error('Error empresas -> fileChangeEvent', error);
    }
  }

  ValidateTypeFile(sFileName) {
    let _validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];
    let blnValid = false;
    for (let j = 0; j < _validFileExtensions.length; j++) {
        let sCurExtension = _validFileExtensions[j];
        if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
            blnValid = true;
            break;
        }
    }
    if (!blnValid) {
        this.toastr.warning("Disculpa, " + sFileName + " no es valido, solo se acepta archivos con extensión: " + _validFileExtensions.join(", "));
        return false;
    }
    return true;
  }

  closeModal(){
    this.dialogRef.close();
  }

}
