import { Store } from '@ngrx/store';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as MenuAction from 'src/app/modules/_global/ngrx/actions/menu.action';
@Component({
  selector: 'app-menu-modulos',
  templateUrl: './menu-modulos.component.html',
  styleUrls: ['./menu-modulos.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: window.innerHeight < 700 ? window.innerHeight : 700 +'px',
        width: '300px',
        opacity: 1
      })),
      state('closed', style({
        height: window.innerHeight < 700 ? window.innerHeight : 700 +'px',
        width: '100px',
        left: '-350px',
        opacity: 1
      })),
      transition('open => closed', [
        animate('0.25s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ]
})
export class MenuModulosComponent implements OnInit, OnDestroy {

  //Subscripciones la NGRX state
  menuListSubscription: Subscription = new Subscription();
  menuSelectedubscription: Subscription = new Subscription();
  loadingMenuSubscription: Subscription = new Subscription();

  menuList = [];
  menuSelected: any = undefined;
  loadingMenuList = false;
  isOpen = false;
  showMenuLateral = false;

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    this.validShowMenuLateral();
  }

  constructor(
    private store: Store<any>,
    private router: Router
  ) {
    this.menuListSubscription = this.store.select((state) => state?.menu?.MenuList).subscribe(MenuList => {
      this.menuList = [];
      if (MenuList != undefined && MenuList != null){
        this.menuList = [...MenuList];
        this.sortModuloList();
      }
    });
    this.menuSelectedubscription = this.store.select((state) => state?.menu?.MenuSelected).subscribe(MenuSelected => {
      this.menuSelected = undefined;
      if (MenuSelected != undefined && MenuSelected != null){
        this.menuSelected = {...MenuSelected};
      }
    });
    this.loadingMenuSubscription = this.store.select((state) => state?.menu?.LoadingMenuList).subscribe(LoadingMenuList =>{
      this.loadingMenuList = false;
      if(LoadingMenuList != undefined){
        this.loadingMenuList = LoadingMenuList;
      }
    });
  }

  ngOnInit() {
    this.store.dispatch(MenuAction.getMenu());
    this.router.ngOnDestroy();
    this.validShowMenuLateral();
  }

  ngOnDestroy(){
    this.menuListSubscription.unsubscribe();
    this.menuSelectedubscription.unsubscribe();
    this.loadingMenuSubscription.unsubscribe();
  }

  sortModuloList(){
    try {
      if(this.menuList.length > 0){
        if(this.menuList[0].orden != undefined && this.menuList[0].orden != null){
          this.menuList.sort(function(a,b){
            if(a.orden < b.orden){
              return -1;
            }else{
              return 1;
            }
          });
        }
      }
    } catch (error) {
      console.error('Error MenuModulosComponent -> sortModuloList:', error);
    }
  }

  vaidatePreSelected(){
    try {
      setTimeout(() => {
        if(this.menuSelected == undefined || this.menuSelected == null){
          const menuPreSelectedList = this.menuList.filter(element => element.seleccionado ===  true);
          if(menuPreSelectedList.length > 0){
            if(menuPreSelectedList[0].ruta != undefined && menuPreSelectedList[0].ruta != null && menuPreSelectedList[0].ruta != ''){
              this.redirectRutaSelected(menuPreSelectedList[0]);
            }
          }
        }
      }, 2000);
    } catch (error) {
      console.error('Error MenuModulosComponent -> vaidatePreSelected:', error);
    }
  }

  validShowMenuLateral(){
    try {
      const widthWindows = window.innerWidth;
      if(widthWindows <= 730){
        this.showMenuLateral = true;
      }else{
        this.showMenuLateral = false;
      }
    } catch (error) {
      console.error('Error MenuModulosComponent -> validShowMenuLateral:', error);
    }
  }

  toggleMenuLateral() {
    try {
      this.isOpen = !this.isOpen;
    } catch (error) {
      console.error('Error MenuModulosComponent -> toggleMenuLateral:', error);
    }
  }

  hideMenuLateral(){
    try {
      this.isOpen = false;
    } catch (error) {
      console.error('Error MenuModulosComponent -> hideMenuLateral:', error);
    }
  }

  redirectRutaSelected(menu){
    try {
      this.router.navigate([menu.ruta]);
    } catch (error) {
      console.error('Error MenuModulosComponent -> redirectRutaSelected:', error);
    }
  }

}
