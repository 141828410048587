import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LoginService {

  urlApi = environment.apiUrl;

  constructor(
    private httpClient: HttpClient,
  ) {}

  validarLogin(usuario: string, clave: string){
    const url = this.urlApi + '/login';
    const body = {user_name: usuario, password: clave};
    return this.httpClient.post(`${url}`, body);
  }
  getPermisosByUsuarioEmpresa(filtros: any){
    const url = this.urlApi + '/permisos';
    return this.httpClient.post(`${url}`, filtros);
  }
  recuperacionPassword(filtros: any){
    const url = this.urlApi + '/recuperar-clave';
    return this.httpClient.post(`${url}`, filtros);
  }
}
