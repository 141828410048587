import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ServiceBase } from '../ServiceBase';


@Injectable({
  providedIn: 'root'
})
export class MenuService {

  urlApi = environment.apiUrl;
  httpClient: any;

  constructor(
    private http: HttpClient,
    private serviceBase: ServiceBase
    ) { }

  getSubmodule( filter?: any ) {
    let url = this.urlApi + '/listar-modulos';
    url = this.serviceBase.crearUrlParametros(url, filter);
    return this.http.get(`${url}`);
  }

  getBuscadorEmpresas( body?: any ){
    let url = this.urlApi + '/buscador-empresas';
    return this.http.post(`${url}`, body);
  }

  getIconoList(){
    let url = this.urlApi + '/icons';
    return this.http.get(`${url}`);
  }

}
