import { Injectable, OnDestroy } from '@angular/core';
import { HttpHandler, HttpRequest, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { LocalStoreService } from 'src/app/modules/_global/service/local-store/local-store.service';

@Injectable()
export class HttpUrlInterceptor implements HttpInterceptor, OnDestroy {

  login: any;
  loginSubscription: Subscription = new Subscription();
  token = '';

  constructor(
    private localStoreService: LocalStoreService
  ) {
  }

  ngOnDestroy(){
    this.loginSubscription.unsubscribe();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    //console.log('HttpUrlInterceptor: ', req);
    let headersApp = new HttpHeaders();
    headersApp = headersApp.set('xkey', '' + environment.xkey);
    this.getTokenByLoginStore();
    headersApp = headersApp.set('xaccess', ''+ this.token);
    headersApp = headersApp.set('Access-Control-Allow-Origin', '*');
    req = req.clone({headers: headersApp});
    return next.handle(req);
  }

  getTokenByLoginStore(){
    this.token = '';
    let sup =  this.localStoreService.get('login').subscribe((item) => {
      //console.log('buscado token', item);
      if(item != undefined && item != null){
        if(item.Data != undefined){
          if(item.Data.token != undefined){
            this.token = item.Data.token;
          }
        }
      }
    });
    sup.unsubscribe();
    //console.log('token',this.token);
  }
}
