import { createAction, props } from '@ngrx/store';

export const setLinkReporte = createAction(
  '[FILE] [SET] REGISTRAR LINK REPORTE',
  props<{ link: any }>()
);


export const cleanLinkReporte = createAction(
  '[FILE] [CLEAN] LIMPIAR LINK REPORTE'
);

export const cleanState = createAction(
  '[FILE] [CLEAN] LIMPIAR STATE REPORTE'
);

export const setError = createAction(
  '[FILE] [ERROR] REGISTRAR ERROR',
  props<{ error: any }>()
);

