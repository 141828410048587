import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Rutas } from './modules/_global/ultil/rutas';
import { LoginRoutes } from './modules/login/login.routing'
const routes: Routes = [
  ...LoginRoutes,
  {
    path: 'inicio',
    loadChildren: () => import('./modules/inicio/inicio.module').then(m => m.InicioModule)
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./modules/usuarios/usuarios.module').then(m => m.UsuariosModule)
  },
  {
    path: 'empresas',
    loadChildren: () => import('./modules/empresas/empresas.module').then(m => m.EmpresasModule)
  },
  {
    path: 'configuraciones',
    loadChildren: () => import('./modules/configuraciones/configuraciones.module').then(m => m.ConfiguracionesModule)
  },
  {
    path: 'docfinanciero',
    loadChildren: () => import('./modules/doc-financiero/doc-financiero.module').then(m => m.DocFinancieroModule)
  },
  {
    path: 'etiquetas',
    loadChildren: () => import('./modules/etiquetas/etiquetas.module').then(m => m.EtiquetasModule)
  },
  {
    path: 'reportes',
    loadChildren: () => import('./modules/reportes/reportes.module').then(m => m.ReportesModule)
  },
  {
    path: 'grupos',
    loadChildren: () => import('./modules/grupos/grupos.module').then(m => m.GruposModule)
  },
  {
    path: 'reporteslogs',
    loadChildren: () => import('./modules/reporte-logs/reporte-logs.module').then(m => m.ReporteLogsModule)
  },
  {
    path: 'directoriosfinanciero',
    loadChildren: () => import('./modules/directoriosfinanciero/directoriosfinanciero.module').then(m => m.DirectoriosFinancieroModule)
  },
  {
    path: '**',
    redirectTo: Rutas.LOGIN,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
