import { createReducer, on, Action } from '@ngrx/store';
import * as FileAction from '../actions/file.action';
export interface fileState {
  Filter: any;
  FileList: any;
  FolderList: any;
  FileSelected: any;
  ShowMenuLateral: boolean;
  Error: any;
  OptionSearchFileList: [];
  EmpresaSelected: any;
}

export const initialStateFile: fileState = {
  Filter: null,
  FileList: [],
  FolderList: [],
  FileSelected: null,
  ShowMenuLateral: false,
  Error: null,
  OptionSearchFileList: [],
  EmpresaSelected: null
};

const _fileReducer = createReducer(
  initialStateFile,
  on(FileAction.getFileList, (state, { filter}) => ({
    ...state,
    Filter: filter,
    FileList: [],
    FolderList: []
  })),
  on(FileAction.refreshFileList, (state, { filter }) => ({
    ...state,
    Filter: filter
  })),
  on(FileAction.setFileList, (state, { fileList, folderList}) => ({
    ...state,
    FileList: fileList,
    FolderList: folderList
  })),
  on(FileAction.cleanFileList, (state) => ({
    ...state,
    FileList: [],
    FolderList: []
  })),
  on(FileAction.setFileSelected, (state, { fileSelected }) => ({
    ...state,
    FileSelected: fileSelected
  })),
  on(FileAction.cleanFileSelected, (state) => ({
    ...state,
    FileSelected: null
  })),
  on(FileAction.setShowMenuDer, (state, { showMenuDer }) => ({
    ...state,
    ShowMenuLateral: showMenuDer
  })),
  on(FileAction.setError, (state, { error}) => ({
    ...state,
    Error: error
  })),
  on(FileAction.getOptionSearchFileList, (state, { filter }) => ({
    ...state,
    Filter: filter
  })),
  on(FileAction.setOptionSearchFileList, (state, { optionList }) => ({
    ...state,
    OptionSearchFileList: optionList
  })),
  on(FileAction.cleanOptionSearchFileList, (state) => ({
    ...state,
    Filter: []
  })),
  on(FileAction.getEmpresaSelected, (state) => ({
    ...state
  })),
  on(FileAction.setEmpresaSelected, (state, { dato }) => ({
    ...state,
    EmpresaSelected: dato
  })),
  on(FileAction.cleanEmpresaSelected, (state) => ({
    ...state,
    EmpresaSelected: null
  })),
  on(FileAction.cleanAll, (state) => ({
    ...initialStateFile,
    Filter: null,
    FileList: [],
    FolderList: [],
    FileSelected: null,
    ShowMenuLateral: false,
    Error: null,
    OptionSearchFileList: [],
    EmpresaSelected: null
  }))
);


export function FileReducer(state: fileState, action: Action) {
  return _fileReducer(state, action);
}

