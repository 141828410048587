import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sizefile'
})
export class SizeFilePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let numberText = value;
    try {
      const numberSize = Number(value);
      if(numberSize >= 0){
        if(numberSize >= 1048576 && numberSize < 1073741824){
          const mb = numberSize / 1048576;
          numberText = mb.toFixed(2) + ' MB';
        }else if(numberSize >= 1073741824){
          const mb = numberSize / 1073741824;
          numberText = mb.toFixed(2) + ' GB';
        }else if(numberSize < 1048576){
          const mb = numberSize / 1024;
          numberText = mb.toFixed(2) + ' KB';
        }else{
          numberText = numberSize + ' B';
        }
      }else{
        numberText = '0';
      }

    } catch (error) {
      console.error('Error SizeFilePipe -> transform()', error);
    }

    return numberText;
  }

}
