<div class="app-modal-login-form">
<div class="nk-block nk-block-large nk-auth-body">
    <div class="brand-logo mb-2">
        <a href="html/index.html" class="logo-link">
            <img class="logo-dark logo-img logo-img-lg" src="./assets/images/logo-blanco.png" srcset="./assets/images/logo-blanco.png 2x" alt="{{ appName }}">
        </a>
    </div>
    <div class="nk-block-head">
        <div class="nk-block-head-content">
            <h5 class="nk-block-title">Por favor vuelve a inciar sesión.</h5>
        </div>
    </div>
    <div class="form-group mt-10">
      <button class="btn btn-lg btn-primary btn-block" (click)="salirApp()">Salir</button>
    </div>
  </div>
</div>

