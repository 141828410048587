import { FooterComponent } from './componets/footer/footer.component';
import { YesNoTextPipe } from './../../pipes/yes-no-text.pipe';
import { BoolToTextPipe } from './../../pipes/bool-to-text.pipe';
import { SizeFilePipe } from './../../pipes/size-file.pipe';
import { Text64Characters } from './../../pipes/text-64-characters';
import { MenuUsuarioComponent } from './componets/menu-usuario/menu-usuario.component';
import { MenuModulosComponent } from './componets/menu-modulos/menu-modulos.component';
import { MenuEmpresaComponent } from './componets/menu-empresa/menu-empresa.component';
import { SubMenuHorizontalComponent } from './componets/configuracion-sub-menu/configuracion-sub-menu.component';
import { ReportesSubMenuComponent } from './componets/reportes-sub-menu/reportes-sub-menu.component';
import { ModalDeleteComponent } from './componets/modal-delete/modal-delete.component';
import { ModalCambiarPasswordComponent } from './componets/modal-cambiar-password/modal-cambiar-password.component';
import { ModalEmpresaUsuarioComponent } from './componets/modal-empresa-usuario/modal-empresa-usuario.component';
import { BodyContainerComponent } from './componets/body-container/body-container.component';
import { MenuPrincipalComponent } from './componets/menu-principal/menu-principal.component';
import { TextSiglasPipe } from '../../pipes/text-siglas.pipe';
import { TypeFilePipe } from '../../pipes/type-file.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule, MatNativeDateModule, DateAdapter } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaskDateDirective } from 'src/app/directives/mask-date.directive';
import { MaskDecimalNegativoDirective } from 'src/app/directives/mask-decimal-negativo.directive';
import { MaskDecimalPositivoDirective } from 'src/app/directives/mask-decimal-positivo.directive';
import { MaskDecimalDirective } from 'src/app/directives/mask-decimal.directive';
import { MaskIntegerNegativoDirective } from 'src/app/directives/mask-integer-negativo.directive';
import { MaskIntegerPositivoDirective } from 'src/app/directives/mask-integer-positivo.directive';
import { MaskIntegerDirective } from 'src/app/directives/mask-integer.directive';
import { SelectInFocusDirective } from 'src/app/directives/select-in-focus.directive';
import { ToastrModule } from 'ngx-toastr';
import { ModalLoginComponent } from './componets/modal-login/modal-login.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatBadgeModule,
    MatDividerModule,
    MatSidenavModule,
    MatTabsModule,
    MatListModule,
    MatTooltipModule,
    MatGridListModule,
    MatTableModule,
    MatCheckboxModule,
    MatInputModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatSortModule,
    MatSelectModule,
    MatStepperModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatCardModule,
    MatRadioModule,
    MatRippleModule,
    MatNativeDateModule,
    MatChipsModule,
    MatDialogModule,
    DragDropModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot({
      closeButton: true,
      progressBar: true,
      enableHtml: true
    }),
    NgxMatSelectSearchModule,
    ColorPickerModule,
    NgxExtendedPdfViewerModule
  ],
  exports: [
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatBadgeModule,
    MatDividerModule,
    MatSidenavModule,
    MatTabsModule,
    MatListModule,
    MatTooltipModule,
    MatGridListModule,
    MatTableModule,
    MatCheckboxModule,
    MatInputModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatSortModule,
    MatSelectModule,
    MatStepperModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatCardModule,
    MatRadioModule,
    MatRippleModule,
    MatNativeDateModule,
    MatChipsModule,
    MatDialogModule,
    DragDropModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    FormsModule,
    MaskDateDirective,
    MaskDecimalNegativoDirective,
    MaskDecimalPositivoDirective,
    MaskDecimalDirective,
    MaskIntegerNegativoDirective,
    MaskIntegerPositivoDirective,
    MaskIntegerDirective,
    SelectInFocusDirective,
    BodyContainerComponent,
    MenuPrincipalComponent,
    MenuEmpresaComponent,
    MenuModulosComponent,
    MenuUsuarioComponent,
    SubMenuHorizontalComponent,
    ReportesSubMenuComponent,
    FooterComponent,
    ModalDeleteComponent,
    ModalCambiarPasswordComponent,
    ModalLoginComponent,
    ModalEmpresaUsuarioComponent,
    TextSiglasPipe,
    YesNoTextPipe,
    BoolToTextPipe,
    SizeFilePipe,
    Text64Characters,
    TypeFilePipe,
    NgxMatSelectSearchModule,
    ColorPickerModule,
    NgxExtendedPdfViewerModule
  ],
  declarations: [
    BodyContainerComponent,
    MenuPrincipalComponent,
    FooterComponent,
    MenuEmpresaComponent,
    MenuModulosComponent,
    MenuUsuarioComponent,
    SubMenuHorizontalComponent,
    ReportesSubMenuComponent,
    ModalDeleteComponent,
    ModalCambiarPasswordComponent,
    ModalLoginComponent,
    ModalEmpresaUsuarioComponent,
    MaskDateDirective,
    MaskDecimalNegativoDirective,
    MaskDecimalPositivoDirective,
    MaskDecimalDirective,
    MaskIntegerNegativoDirective,
    MaskIntegerPositivoDirective,
    MaskIntegerDirective,
    SelectInFocusDirective,
    TextSiglasPipe,
    YesNoTextPipe,
    BoolToTextPipe,
    SizeFilePipe,
    TypeFilePipe,
    Text64Characters
  ],
  providers: [
  ]
})
export class GlobalModule{
  constructor(
  ){}
}
