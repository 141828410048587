import { LoginModule } from './modules/login/login.module';

import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { getDutchPaginatorIntl } from './modules/_global/service/crud-basic/dutch-paginator-intl';
import { MY_DATE_FORMATS} from './modules/_global/ultil/formats'
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { HttpUrlInterceptor } from './interceptors/http-url.interceptor';

import { loginReducer } from './modules/_global/ngrx/reducers/login.reducer';
import { MenuReducer } from './modules/_global/ngrx/reducers/menu.reducer';
import { DataGridReducer } from './modules/_global/ngrx/reducers/dataGrid.reducer';
import { ItemFormReducer } from './modules/_global/ngrx/reducers/itemForm.reducer';
import { FileReducer } from './modules/_global/ngrx/reducers/file.reducer';
import { ReporteReducer } from './modules/_global/ngrx/reducers/reporte.reducer';
import { LoginEffects } from './modules/_global/ngrx/effects/login.effects';
import { MenuEffects } from './modules/_global/ngrx/effects/menu.effects';
import { CrudBasicEffects } from './modules/_global/ngrx/effects/dataGrid.effects';
import { ItemFormEffects } from './modules/_global/ngrx/effects/itemForm.effects';
import { FileEffects } from './modules/_global/ngrx/effects/file.effects';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    StoreModule.forRoot({
      login: loginReducer,
      dataGrid: DataGridReducer,
      itemForm: ItemFormReducer,
      file: FileReducer,
      reporte: ReporteReducer,
      menu: MenuReducer
    }),
    EffectsModule.forRoot([
      LoginEffects,
      CrudBasicEffects,
      ItemFormEffects,
      FileEffects,
      MenuEffects
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 60,
      logOnly: environment.production,
    }),
    BrowserAnimationsModule,
    LoginModule
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpUrlInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
