import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';

import { Store } from '@ngrx/store';

import * as LoginAction from 'src/app/modules/_global/ngrx/actions/login.action';
import * as MenuAction from 'src/app/modules/_global/ngrx/actions/menu.action';
import { LoginService } from 'src/app/modules/_global/service/login/login.service';
import { LocalStoreService } from 'src/app/modules/_global/service/local-store/local-store.service';
import { EffectsBase } from './EffectsBase';

@Injectable()
export class LoginEffects {

  moduloListSubscription: Subscription = new Subscription();
  moduloList = [];
  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private loginService: LoginService,
    private localStoreService: LocalStoreService,
    private effectsBase: EffectsBase
  ) {}

  //VALIDAR ACCESO LOGIN
  ValidateAccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginAction.validateAccess),
      exhaustMap(action =>
        this.loginService.validarLogin(action.user, action.password).pipe(
          map((resApi: any) => {
            try{
              const respuesta = this.effectsBase.TransformarRespuestaWebApi(resApi);
              return LoginAction.setStore({ respuesta: respuesta});
            }catch(errorRes){
              this.store.dispatch(LoginAction.setError({ error: errorRes }));
            }
          }),
          catchError(errorRes => {
            return of(LoginAction.setError({ error: errorRes }));
          })
        )
    ))
  );

  //VALIDAR ACCESO LOGIN
  GetLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginAction.get),
      exhaustMap(action =>
        this.localStoreService.get('login').pipe(
          map((resp: any) => {
            try{
              return LoginAction.set({ respuesta: resp });
            }catch(errorRes){
              this.store.dispatch(LoginAction.setError({ error: errorRes }));
            }
          }),
          catchError(errorRes => {
            return of(LoginAction.setError({ error: errorRes }));
          })
        )
    ))
  );

  //CARGAR RESPUESTA DEL SERVICIO LOGIN
  SetStoreLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginAction.setStore),
      tap((action) => {
        this.localStoreService.set('login', action.respuesta).subscribe(() => {
          this.store.dispatch(LoginAction.set({respuesta: action.respuesta}));
          this.store.dispatch(MenuAction.getEmpresaList());
          this.store.dispatch(MenuAction.getMenu());
          this.store.dispatch(MenuAction.getUsuario());
          this.store.dispatch(MenuAction.getEmpresaSelected());
        });
      })
    ),
    { dispatch: false }
  );

  //LIMPIAR LOGIN
  CleanLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginAction.clean),
      exhaustMap(action =>
        this.localStoreService.removeAll().pipe(
          map((resp: any) => {
            try{
              return LoginAction.setCleanOk();
            }catch(errorRes){
              this.store.dispatch(LoginAction.setError({ error: errorRes }));
            }
          }),
          catchError(errorRes => {
            return of(LoginAction.setError({ error: errorRes }));
          })
        )
    ))
  );

}
