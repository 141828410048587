<div class="app-modal-empresa-usuario">
  <mat-dialog-content>
    <a class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <em class="icon ni ni-cross"></em>
    </a>
    <h5 class="modal-title">Mi Empresa</h5>
    <mat-divider></mat-divider>
    <div class="card">
      <div class="row mt-3">
        <div class="col-12 d-flex justify-content-center">
          <div class="user-avatar" *ngIf="empresaSelected.logo !== undefined && empresaSelected.logo !== null">
            <img [src]="empresaSelected.logo" alt="" width="71" height="71">
          </div>
          <div class="user-avatar" *ngIf="empresaSelected.logo === undefined || empresaSelected.logo === null">
            {{ empresaSelected?.nombre | textSiglas}}
          </div>
        </div>
      </div>
      <div class="card-body ">
        <h5 class="card-title text-center">{{empresaSelected?.nombre}}</h5>
        <div class="row mt-3">
          <div class="col-12">
            <p class="text-center">
             <strong>{{empresaSelected?.tipo_identificacion}} - {{empresaSelected?.ruc}}</strong>
            </p>
          </div>
          <div class="col-12">
            <p class="text-center">
              <strong>Pais:</strong> {{empresaSelected?.pais}}
            </p>
          </div>
          <div class="col-12">
            <p class="text-center">
              <strong>Expediente:</strong> {{empresaSelected?.expediente}}
            </p>
          </div>
          <div class="col-12">
            <p class="text-center">
              <strong>RAET:</strong> {{empresaSelected?.raet}}
            </p>
          </div>
          <div class="col-12">
            <p class="text-center">
              <strong>Fecha inicio operaciones:</strong> {{empresaSelected?.fecha_inicio_operaciones}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="warn" (click)="closeModal()">Aceptar</button>
  </mat-dialog-actions>
</div>
