import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-body-container',
  templateUrl: './body-container.component.html',
  styleUrls: ['./body-container.component.scss']
})
export class BodyContainerComponent implements OnInit {

  heightWindows = '800px';
  /*@HostListener('window:resize', ['$event'])
  onResize() {
    this.validarRize();
  }*/
  constructor() { }

  ngOnInit() {
    this.validarRize();
  }

  validarRize(){
    this.heightWindows= (window.innerHeight - 50) + 'px';
  }

}
