import { createReducer, on, Action } from '@ngrx/store';
import * as loginAction from '../actions/login.action';

interface loginState {
  Respuesta: any;
  Filtros: any;
  Error: any;
}

const initialStateLogin: loginState = {
  Respuesta: null,
  Filtros: null,
  Error: null
};

const _loginReducer = createReducer(
  initialStateLogin,
  //ACCESO
  on(loginAction.validateAccess, (state) => ({
    ...initialStateLogin
  })),
  on(loginAction.get, (state) => ({
    ...state
  })),
  on(loginAction.set, (state, {respuesta}) => ({
    ...state,
    Respuesta: respuesta,
    Error: null
  })),
  on(loginAction.setStore, (state, {respuesta}) => ({
    ...state,
    Respuesta: respuesta,
    Error: null
  })),
  //REGISTRAR ERROR
  on(loginAction.setError, (state, {error}) => ({
    ...initialStateLogin,
    Respuesta: null,
    Error: error
  })),
  //LIMPIAR TODO EL STORE DE LOGIN
  on(loginAction.clean, (state) => ({
    ...initialStateLogin
  }))
);

export function loginReducer(state: loginState, action: Action) {
  return _loginReducer(state, action);
}
