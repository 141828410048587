import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNoText'
})
export class YesNoTextPipe implements PipeTransform {

  transform(value: boolean, ...args: unknown[]): string {
    return value ? "Si" : "No";
  }

}
