import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as uuid from 'uuid';
import * as ItemFormAction from 'src/app/modules/_global/ngrx/actions/itemForm.action';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ConvertirDatos } from 'src/app/modules/_global/ultil/ConvertirDatos';
import * as MenuAction from 'src/app/modules/_global/ngrx/actions/menu.action';
import { TileStyler } from '@angular/material/grid-list/tile-styler';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {

  formId = 'ReporteForm-'+uuid.v4();
  rutaControl = new FormControl('',[Validators.required, Validators.minLength(1)]);
  convertirDatos = new ConvertirDatos();

  menuSelectedSubscription = new Subscription();
  empresaSelectedSubscription = new Subscription();
  respuestaSubscription: Subscription = new Subscription();

  empresaSelected: any = undefined;
  menuSelected: any = undefined;
  ultimo_tipo: string = "";

  constructor(
    private store: Store<any>,
    public dialogRef: MatDialogRef<FormComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    if(this.data != undefined && this.data.item != undefined){
      this.rutaControl.setValue(this.data.item.ruta_reporte);
    }
    this.menuSelectedSubscription = this.store.select((state) => state?.menu?.MenuSelected).subscribe(MenuSelected => {
      if(MenuSelected != undefined && MenuSelected != null){
        this.menuSelected = {...MenuSelected};
      }
    });
    this.empresaSelectedSubscription = this.store.select((state) => state?.menu?.EmpresaSelected).subscribe(EmpresaSelected => {
      this.empresaSelected = undefined;
      if(EmpresaSelected != undefined && EmpresaSelected != null){
        this.empresaSelected = {...EmpresaSelected};
      }
    });

    this.respuestaSubscription = this.store.select((state) => state?.itemForm?.Respuesta[this.formId]).subscribe(Respuesta => {
      let mensajes = '';
      if(Respuesta != undefined && Respuesta != null){
        if(Respuesta.Messages != undefined && Respuesta.Messages != null){
          mensajes = this.convertirDatos.toastrMensaje(Respuesta.Messages);
        }
        if(Respuesta.Status != undefined && Respuesta.Status != null){
          if(Respuesta.Status === true){
            if(mensajes.length === 0){
              mensajes = "Se guardo el registro correctamente.";
            }
            this.toastr.success(mensajes);
            this.closeModal();
          }else{
            if(mensajes.length === 0){
              mensajes = "No se puedo guardar el registro correctamente.";
            }
            this.toastr.error(mensajes);
          }
        }
      }
    });
  }

  ngOnDestroy(){
    this.menuSelectedSubscription.unsubscribe();
    this.empresaSelectedSubscription.unsubscribe();
    this.respuestaSubscription.unsubscribe();
    this.getSubmoduloList();
  }

  guardarFormulario(){
    try {
      const datosGuardar = this.getFormulario();
      if(datosGuardar != null){
        this.store.dispatch(ItemFormAction.actulizarDatos({
          modulo: 'reportes',
          formId: this.formId,
          datosGuardar: datosGuardar,
          filtros: null
        }));
      }
    } catch (error) {
      console.error('Error FormComponent guardarFormulario');
    }
  }

  getFormulario(): object{
    try {
      const ruta = this.rutaControl.value;
      const tipo_file = ruta.split(".")
      const tipeFile = tipo_file[tipo_file.length -1]

      
      
      if(tipeFile!="png") {
        this.ultimo_tipo = "url"
      }else{
        this.ultimo_tipo = tipeFile;
      }
      
      if(ruta == null || ruta == '' || ruta == undefined){
        this.toastr.clear();
        this.toastr.error('La <strong>URL</strong> ingresada no puede estar vacia.');
        this.rutaControl.invalid;
      }else{        
        const datosFormulario = {
          'id_reporte': this.data.item.id_reporte,
          'empresa': this.empresaSelected._id,
          'ruta': this.rutaControl.value,
          'tipo':this.ultimo_tipo
        };
        return datosFormulario;
      }
    } catch (error) {
      console.error('Error FormComponent guardarFormulario');
    }
    return null;
  }

  getSubmoduloList(){
    try {
      this.store.dispatch(MenuAction.cleanSubmenuList());
      if(this.menuSelected != undefined && this.menuSelected != null){
        this.store.dispatch(MenuAction.getSubmenuList({
          filter: [
            'filter[where][id_padre]=' + this.menuSelected._id,
            'filter[where][id_empresa]=' + this.empresaSelected._id,
            'filter[where][id_grupo]=' + this.empresaSelected.grupo
          ]
        }));
      }else{
        this.toastr.clear();
        this.toastr.info('Estamos obteniendo menú configuraciones');
        setTimeout(() => {
          if(this.menuSelected != undefined && this.menuSelected != null){
            this.store.dispatch(MenuAction.getSubmenuList({
              filter: [
                'filter[where][id_padre]=' + this.menuSelected._id,
                'filter[where][id_empresa]=' + this.empresaSelected._id,
                'filter[where][id_grupo]=' + this.empresaSelected.grupo
              ]
          }));
          }
        }, 1000);
      }
    } catch (error) {
      console.error('Error MenuModulosComponent -> getSubmoduloList:', error);
    }
  }

  closeModal(){
    this.dialogRef.close();
  }

}
