<div class="app-configuracion-sub-menu">
  <mat-toolbar>
    <mat-toolbar-row *ngIf="!showBtnMenu">
      <a mat-button  aria-label=""
      *ngFor="let item of submenuList"
      (click)="redirectRutaSelected(item)">
        {{item?.nombre ? item?.nombre : ''}}
      </a>
      <a mat-button class="" aria-label=""
      *ngIf="loading">
        <span class="visually-hidden mr-2">Cargando</span>
        <div class="spinner-border text-primary" role="status">
        </div>
      </a>
      <span class="menu-spacer"></span>
      <a mat-button class="" aria-label=""
      (click)="getSubmoduloList()">
        <mat-icon matToolTip="Actualizar Menú">refresh</mat-icon>
      </a>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="showBtnMenu">
      <button mat-icon-button (click)="toggleMenuLateral()">
        <span class="mr-1">Menú Configuraciones</span><mat-icon>menu</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <div *ngIf="showMenuLateral && showBtnMenu">
    <div class="card no-padding menu-flotante-izq" [@openClose]="showMenuLateral ? 'open' : 'closed'">
      <div class="card-header bg-primary">
        <a class="btn btn-group-refresh text-white" (click)="hideMenuLateral()">
          <mat-icon class="mr-1">arrow_back_ios</mat-icon> <span>Ocultar</span>
        </a>
        <span class="menu-spacer"></span>
        <a mat-button class="" aria-label=""
        (click)="getSubmoduloList()">
          <mat-icon matToolTip="Actualizar Menú">refresh</mat-icon>
        </a>
      </div>
      <div class="card-body">
        <ul class="nav flex-column" *ngIf="submenuList.length > 0">
          <li class="nav-item btn text-left" *ngFor="let item of submenuList">
            <a class="nav-link" (click)="redirectRutaSelected(item)">
              <span class="nk-menu-text">{{ item?.nombre ?  item?.nombre : ''}}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
