import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as ItemFormAction from 'src/app/modules/_global/ngrx/actions/itemForm.action';
@Component({
  selector: 'app-modal-delete',
  templateUrl: './modal-delete.component.html',
  styleUrls: ['./modal-delete.component.scss']
})
export class ModalDeleteComponent implements OnInit {

  constructor(
    private store: Store<any>,
    public dialogRef: MatDialogRef<ModalDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {

  }

  ngOnInit() {

  }

  deleteItems(){
    this.store.dispatch(ItemFormAction.actulizarDatos({
      modulo: this.data.modulo,
      formId: this.data.formId,
      datosGuardar: {
        activo: false,
        _id: this.data.id
      },
      filtros:  null
    }));
  }

  closeModal(){
    this.dialogRef.close();
  }
}
