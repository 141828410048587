import { createReducer, on, Action } from '@ngrx/store';
import * as ItemAction from '../actions/itemForm.action';
export interface itemFormState {
  Formulario: any;
  Respuesta: any;
}
export const initialStateItemForm: itemFormState = {
  Formulario: {},
  Respuesta: {}
};

const _reducer = createReducer(
  initialStateItemForm,
  on(ItemAction.setInciarForm, (state, {formId}) => {
    //INICIO EL FORMULARIO
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      modulo: null,
      filtros: null,
      datosForm: null,
      datosGuardar: null,
      error: null
    };
    //INICIO LA RESPUESTA DEL FORMULARIO
    const Respuesta = {...state.Respuesta};
    Respuesta[formId] = {
      ...state.Respuesta[formId]
    };
    return {
      ...state.Respuesta[formId],
      Formulario,
      Respuesta
    };
  }),
  on(ItemAction.clearByFormId, (state, {formId}) => {
    const Formulario = {...state.Formulario};
    delete Formulario[formId];
    const Respuesta = {...state.Respuesta};
    delete Respuesta[formId];
    return {
      ...state,
      Formulario,
      Respuesta
    };
  }),
  on(ItemAction.clearAll, (state) => ({
    ...initialStateItemForm,
    Formulario: null,
    Respuesta: null
  })),
  on(ItemAction.getDatos, (state, {modulo, formId, filtros}) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      modulo,
      filtros
    };
    return {
      ...state,
      Formulario
    };
  }),
  on(ItemAction.setDatos, (state, {formId, datosForm}) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      datosForm
    };
    return {
      ...state,
      Formulario
    };
  }),
  on(ItemAction.clearDatos, (state, {formId}) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      datosForm: null
    };
    return {
      ...state,
      Formulario
    };
  }),
  on(ItemAction.setError, (state, {formId, error}) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      error
    };
    return {
      ...state,
      Formulario
    };
  }),
  on(ItemAction.guardarDatos, (state, {modulo, formId, datosGuardar}) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      modulo,
      datosGuardar
    };
    return {
      ...state,
      Formulario
    };
  }),
  on(ItemAction.uploadFile, (state, {modulo, formId, datosGuardar}) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      modulo,
      datosGuardar
    };
    return {
      ...state,
      Formulario
    };
  }),
  on(ItemAction.actulizarDatos, (state, {modulo, formId, datosGuardar, filtros }) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      modulo,
      datosGuardar,
      filtros
    };
    return {
      ...state,
      Formulario
    };
  }),
  on(ItemAction.deleteDatos, (state, {modulo, formId, filtros }) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      modulo,
      filtros
    };
    return {
      ...state,
      Formulario
    };
  }),
  on(ItemAction.getEmpresas, (state, {modulo, formId, filtros}) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      modulo,
      filtros
    };
    return {
      ...state,
      Formulario
    };
  }),
  on(ItemAction.setEmpresas, (state, {formId, datos}) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      empresas: datos
    };
    return {
      ...state,
      Formulario
    };
  }),
  on(ItemAction.getGrupos, (state, {modulo, formId, filtros}) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      modulo,
      filtros
    };
    return {
      ...state,
      Formulario
    };
  }),
  on(ItemAction.setGrupos, (state, {formId, datos}) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      grupos: datos
    };
    return {
      ...state,
      Formulario
    };
  }),
  on(ItemAction.getEmpresasGruposByUsuario, (state, {modulo, formId, filtros}) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      modulo,
      filtros
    };
    return {
      ...state,
      Formulario
    };
  }),
  on(ItemAction.setEmpresasGruposByUsuario, (state, {formId, datos}) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      empresasGruposByUsuario: datos
    };
    return {
      ...state,
      Formulario
    };
  }),
  on(ItemAction.getAllModulo, (state, {formId}) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      ModuloList: null
    };
    return {
      ...state,
      Formulario
    };
  }),
  on(ItemAction.setAllModulo, (state, {formId, moduloList}) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      ModuloList: moduloList
    };
    return {
      ...state,
      Formulario
    };
  }),
  on(ItemAction.cleanAllModulo, (state, {formId}) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      ModuloList: null
    };
    return {
      ...state,
      Formulario
    };
  }),
  on(ItemAction.getSubModuloByModulo, (state, {formId, id_modulo}) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      SubModuloList: null,
      IdModulo: id_modulo
    };
    return {
      ...state,
      Formulario
    };
  }),
  on(ItemAction.setSubModuloByModulo, (state, {formId, subModuloList}) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      SubModuloList: subModuloList
    };
    return {
      ...state,
      Formulario
    };
  }),
  on(ItemAction.cleanSubModuloByModulo, (state, {formId}) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      SubModuloList: null,
      IdModulo: null
    };
    return {
      ...state,
      Formulario
    };
  }),
  on(ItemAction.setRespuesta, (state, {formId, code, status, data, messages}) => {
    const Respuesta = {...state.Respuesta};
    Respuesta[formId] = {
      ...state.Respuesta[formId],
      Code: code,
      Status: status,
      Data: data,
      Messages: messages
    };
    return {
      ...state,
      Respuesta
    };
  }),
  on(ItemAction.clearRespuesta, (state, {formId}) => {
    const Respuesta = {...state.Respuesta};
    Respuesta[formId] = {};
    return {
      ...state,
      Respuesta
    };
  }),
  on(ItemAction.setRefrescarLogin, (state, {modulo, formId, datosGuardar}) => {
    const Formulario = {...state.Formulario};
    Formulario[formId] = {
      ...state.Formulario[formId],
      modulo,
      datosGuardar
    };
    return {
      ...state,
      Formulario
    };
  })
);

export function ItemFormReducer(state: itemFormState, action: Action) {
  return _reducer(state, action);
}
