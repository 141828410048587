export const paisList = [
	{
		"id" : 144,
		"pais" : "Afganistán"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 114,
		"pais" : "Albania"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 18,
		"pais" : "Alemania"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 98,
		"pais" : "Algeria"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 145,
		"pais" : "Andorra"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 119,
		"pais" : "Angola"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 4,
		"pais" : "Anguilla"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 147,
		"pais" : "Antigua y Barbuda"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 207,
		"pais" : "Antillas Holandesas"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 91,
		"pais" : "Arabia Saudita"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 5,
		"pais" : "Argentina"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 6,
		"pais" : "Armenia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 142,
		"pais" : "Aruba"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 1,
		"pais" : "Australia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 2,
		"pais" : "Austria"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 3,
		"pais" : "Azerbaiyán"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 80,
		"pais" : "Bahamas"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 127,
		"pais" : "Bahrein"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 149,
		"pais" : "Bangladesh"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 128,
		"pais" : "Barbados"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 9,
		"pais" : "Bélgica"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 8,
		"pais" : "Belice"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 151,
		"pais" : "Benín"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 10,
		"pais" : "Bermudas"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 7,
		"pais" : "Bielorrusia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 123,
		"pais" : "Bolivia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 79,
		"pais" : "Bosnia y Herzegovina"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 100,
		"pais" : "Botsuana"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 12,
		"pais" : "Brasil"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 155,
		"pais" : "Brunéi"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 11,
		"pais" : "Bulgaria"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 156,
		"pais" : "Burkina Faso"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 157,
		"pais" : "Burundi"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 152,
		"pais" : "Bután"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 159,
		"pais" : "Cabo Verde"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 158,
		"pais" : "Camboya"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 31,
		"pais" : "Camerún"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 32,
		"pais" : "Canadá"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 130,
		"pais" : "Chad"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 81,
		"pais" : "Chile"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 35,
		"pais" : "China"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 33,
		"pais" : "Chipre"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 82,
		"pais" : "Colombia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 164,
		"pais" : "Comores"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 112,
		"pais" : "Congo (Brazzaville)"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 165,
		"pais" : "Congo (Kinshasa)"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 166,
		"pais" : "Cook, Islas"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 84,
		"pais" : "Corea del Norte"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 69,
		"pais" : "Corea del Sur"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 168,
		"pais" : "Costa de Marfil"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 36,
		"pais" : "Costa Rica"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 71,
		"pais" : "Croacia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 113,
		"pais" : "Cuba"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 22,
		"pais" : "Dinamarca"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 169,
		"pais" : "Djibouti, Yibuti"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 103,
		"pais" : "Ecuador"
		,
		"tipo" : "RUC"
	},
	{
		"id" : 23,
		"pais" : "Egipto"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 51,
		"pais" : "El Salvador"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 93,
		"pais" : "Emiratos Árabes Unidos"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 173,
		"pais" : "Eritrea"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 52,
		"pais" : "Eslovaquia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 53,
		"pais" : "Eslovenia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 28,
		"pais" : "España"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 55,
		"pais" : "Estados Unidos"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 68,
		"pais" : "Estonia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 121,
		"pais" : "Etiopía"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 175,
		"pais" : "Feroe, Islas"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 90,
		"pais" : "Filipinas"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 63,
		"pais" : "Finlandia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 176,
		"pais" : "Fiyi"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 64,
		"pais" : "Francia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 180,
		"pais" : "Gabón"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 181,
		"pais" : "Gambia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 21,
		"pais" : "Georgia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 105,
		"pais" : "Ghana"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 143,
		"pais" : "Gibraltar"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 184,
		"pais" : "Granada"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 20,
		"pais" : "Grecia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 94,
		"pais" : "Groenlandia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 17,
		"pais" : "Guadalupe"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 185,
		"pais" : "Guatemala"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 186,
		"pais" : "Guernsey"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 187,
		"pais" : "Guinea"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 172,
		"pais" : "Guinea Ecuatorial"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 188,
		"pais" : "Guinea-Bissau"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 189,
		"pais" : "Guyana"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 16,
		"pais" : "Haiti"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 137,
		"pais" : "Honduras"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 73,
		"pais" : "Hong Kong"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 14,
		"pais" : "Hungría"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 25,
		"pais" : "India"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 74,
		"pais" : "Indonesia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 140,
		"pais" : "Irak"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 26,
		"pais" : "Irán"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 27,
		"pais" : "Irlanda"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 215,
		"pais" : "Isla Pitcairn"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 83,
		"pais" : "Islandia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 228,
		"pais" : "Islas Salomón"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 58,
		"pais" : "Islas Turcas y Caicos"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 154,
		"pais" : "Islas Virgenes Británicas"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 24,
		"pais" : "Israel"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 29,
		"pais" : "Italia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 132,
		"pais" : "Jamaica"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 70,
		"pais" : "Japón"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 193,
		"pais" : "Jersey"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 75,
		"pais" : "Jordania"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 30,
		"pais" : "Kazajstán"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 97,
		"pais" : "Kenia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 34,
		"pais" : "Kirguistán"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 195,
		"pais" : "Kiribati"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 37,
		"pais" : "Kuwait"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 196,
		"pais" : "Laos"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 197,
		"pais" : "Lesotho"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 38,
		"pais" : "Letonia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 99,
		"pais" : "Líbano"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 198,
		"pais" : "Liberia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 39,
		"pais" : "Libia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 126,
		"pais" : "Liechtenstein"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 40,
		"pais" : "Lituania"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 41,
		"pais" : "Luxemburgo"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 85,
		"pais" : "Macedonia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 134,
		"pais" : "Madagascar"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 76,
		"pais" : "Malasia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 125,
		"pais" : "Malawi"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 200,
		"pais" : "Maldivas"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 133,
		"pais" : "Malí"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 86,
		"pais" : "Malta"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 131,
		"pais" : "Man, Isla de"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 104,
		"pais" : "Marruecos"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 201,
		"pais" : "Martinica"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 202,
		"pais" : "Mauricio"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 108,
		"pais" : "Mauritania"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 42,
		"pais" : "México"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 43,
		"pais" : "Moldavia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 44,
		"pais" : "Mónaco"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 139,
		"pais" : "Mongolia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 117,
		"pais" : "Mozambique"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 205,
		"pais" : "Myanmar"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 102,
		"pais" : "Namibia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 206,
		"pais" : "Nauru"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 107,
		"pais" : "Nepal"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 209,
		"pais" : "Nicaragua"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 210,
		"pais" : "Níger"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 115,
		"pais" : "Nigeria"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 212,
		"pais" : "Norfolk Island"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 46,
		"pais" : "Noruega"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 208,
		"pais" : "Nueva Caledonia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 45,
		"pais" : "Nueva Zelanda"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 213,
		"pais" : "Omán"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 19,
		"pais" : "Países Bajos, Holanda"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 87,
		"pais" : "Pakistán"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 124,
		"pais" : "Panamá"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 88,
		"pais" : "Papúa-Nueva Guinea"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 110,
		"pais" : "Paraguay"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 89,
		"pais" : "Perú"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 178,
		"pais" : "Polinesia Francesa"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 47,
		"pais" : "Polonia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 48,
		"pais" : "Portugal"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 246,
		"pais" : "Puerto Rico"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 216,
		"pais" : "Qatar"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 13,
		"pais" : "Reino Unido"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 65,
		"pais" : "República Checa"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 138,
		"pais" : "República Dominicana"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 49,
		"pais" : "Reunión"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 217,
		"pais" : "Ruanda"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 72,
		"pais" : "Rumanía"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 50,
		"pais" : "Rusia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 242,
		"pais" : "Sáhara Occidental"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 223,
		"pais" : "Samoa"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 219,
		"pais" : "San Cristobal y Nevis"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 224,
		"pais" : "San Marino"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 221,
		"pais" : "San Pedro y Miquelón"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 225,
		"pais" : "San Tomé y Príncipe"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 222,
		"pais" : "San Vincente y Granadinas"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 218,
		"pais" : "Santa Elena"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 220,
		"pais" : "Santa Lucía"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 135,
		"pais" : "Senegal"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 226,
		"pais" : "Serbia y Montenegro"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 109,
		"pais" : "Seychelles"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 227,
		"pais" : "Sierra Leona"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 77,
		"pais" : "Singapur"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 106,
		"pais" : "Siria"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 229,
		"pais" : "Somalia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 120,
		"pais" : "Sri Lanka"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 141,
		"pais" : "Sudáfrica"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 232,
		"pais" : "Sudán"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 67,
		"pais" : "Suecia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 66,
		"pais" : "Suiza"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 54,
		"pais" : "Surinam"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 234,
		"pais" : "Swazilandia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 56,
		"pais" : "Tadjikistan"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 92,
		"pais" : "Tailandia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 78,
		"pais" : "Taiwan"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 101,
		"pais" : "Tanzania"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 171,
		"pais" : "Timor Oriental"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 136,
		"pais" : "Togo"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 235,
		"pais" : "Tokelau"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 236,
		"pais" : "Tonga"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 237,
		"pais" : "Trinidad y Tobago"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 122,
		"pais" : "Túnez"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 57,
		"pais" : "Turkmenistan"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 59,
		"pais" : "Turquía"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 239,
		"pais" : "Tuvalu"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 62,
		"pais" : "Ucrania"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 60,
		"pais" : "Uganda"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 111,
		"pais" : "Uruguay"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 61,
		"pais" : "Uzbekistán"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 240,
		"pais" : "Vanuatu"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 95,
		"pais" : "Venezuela"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 15,
		"pais" : "Vietnam"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 241,
		"pais" : "Wallis y Futuna"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 243,
		"pais" : "Yemen"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 116,
		"pais" : "Zambia"
		,
		"tipo" : "IDENTIFICACION"
	},
	{
		"id" : 96,
		"pais" : "Zimbabwe"
	}
]
