export const modalLoginValidation = {
  usuario: {
    required: '<b>Usuario</b> es un campo requerido.',
    blank: '<b>Usuario</b> no puede estar vacio.'
  },
  clave: {
    required: '<b>Contraseña</b> es un campo requerido.',
    blank: '<b>Contraseña</b> no puede estar vacio.'
  }
};
