import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-modal-empresa-usuario',
  templateUrl: './modal-empresa-usuario.component.html',
  styleUrls: ['./modal-empresa-usuario.component.scss']
})
export class ModalEmpresaUsuarioComponent implements OnInit, OnDestroy {

  empresaSelectedSubscription = new Subscription();
  empresaSelected: any;
  constructor(
    private store: Store<any>,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalEmpresaUsuarioComponent>
  ) {
    this.empresaSelectedSubscription = this.store.select((state) => state?.menu?.EmpresaSelected).subscribe(EmpresaSelected => {
      this.empresaSelected = undefined;
      if(EmpresaSelected != undefined && EmpresaSelected!= null){
        this.empresaSelected = {...EmpresaSelected};
        if(this.empresaSelected != undefined && this.empresaSelected != null){
          this.empresaSelected.fecha_inicio_operaciones = moment(this.empresaSelected.fecha_inicio_operaciones).format('DD/MM/yyyy');
        }
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(){
    this.empresaSelectedSubscription.unsubscribe();
  }

  closeModal(){
    this.dialogRef.close();
  }
}
