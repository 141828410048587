import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class ValidacionesUtil{

  verificarRuc(ruc): boolean {
    if (typeof(ruc) == 'string' && ruc.length == 13 && /^\d+$/.test(ruc)) {
      let digitos = ('0' + ruc).split('').map(Number);
      let codigo_provincia = digitos[1] * 10 + digitos[2];

      if (codigo_provincia >= 1 && (codigo_provincia <= 24 || codigo_provincia == 30)) {
        if (digitos[11] + digitos[12] + digitos[13] == 1) {
          let sumatotal = 0;
          let VAL1;
          let VAL2;
          let VAL3;
          let VAL4;
          let VAL5;
          let VAL6;
          let VAL7;
          let VAL8;
          let VAL9;
          let DVER;
          let SUMA;
          let RESD;
          let NCV;

          switch (digitos[3])
          {
            //PERSONA JURIDICA
            case 9:
              VAL1 = digitos[1] * 4;
              VAL2 = digitos[2] * 3;
              VAL3 = digitos[3] * 2;
              VAL4 = digitos[4] * 7;
              VAL5 = digitos[5] * 6;
              VAL6 = digitos[6] * 5;
              VAL7 = digitos[7] * 4;
              VAL8 = digitos[8] * 3;
              VAL9 = digitos[9] * 2;
              DVER = digitos[10];
              SUMA = VAL1 + VAL2 + VAL3 + VAL4 + VAL5 + VAL6 + VAL7 + VAL8 + VAL9;
              RESD = SUMA % 11;
              if (RESD == 1) {
                return false;
              }

              NCV = RESD;
              if (RESD != 0) {
                  NCV = 11 - RESD;
              }

              if (DVER == NCV) {
                return true;
              }

              break;
            //PERSONA PUBLICA
            case 6:
              VAL1 = digitos[1] * 3;
              VAL2 = digitos[2] * 2;
              VAL3 = digitos[3] * 7;
              VAL4 = digitos[4] * 6;
              VAL5 = digitos[5] * 5;
              VAL6 = digitos[6] * 4;
              VAL7 = digitos[7] * 3;
              VAL8 = digitos[8] * 2;
              DVER = digitos[9];
              SUMA = VAL1 + VAL2 + VAL3 + VAL4 + VAL5 + VAL6 + VAL7 + VAL8;
              RESD = SUMA % 11;
              if (RESD == 1) {
                return false;
              }

              NCV = RESD;
              if (RESD != 0) {
                  NCV = 11 - RESD;
              }

              if (DVER == NCV) {
                return true;
              }

              break;
            //PERSONA NATURAL
            case 7:
            case 8:
            case 5:
            case 4:
            case 3:
            case 2:
            case 1:
            case 0:
              for (let i = 1; i < digitos.length - 4; i++)
              {
                  let valor = digitos[i];

                  if (i % 2 != 0)
                  {
                      valor = digitos[i] * 2;
                      if (valor > 9) {
                          valor = valor - 9;
                      }
                  }
                  sumatotal += valor;
              }

              let digito_verificador = sumatotal % 10;

              if (digito_verificador != 0) {
                  digito_verificador = 10 - (sumatotal % 10);
              }

              if (digitos[10] == digito_verificador) {
                return true;
              }

              break;
          }
        }
      }
    }
    return false;
  }


 verificarCedula(cedula): boolean {
  if (typeof(cedula) == 'string' && cedula.length == 10 && /^\d+$/.test(cedula)) {
    let digitos = ('0' + cedula).split('').map(Number);
    let codigo_provincia = digitos[1] * 10 + digitos[2];

    if (codigo_provincia >= 1 && (codigo_provincia <= 24 || codigo_provincia == 30)) {
      let sumatotal = 0;
      for (let i = 1; i < digitos.length - 1; i++)
      {
          let valor = digitos[i];
          if (i % 2 != 0)
          {
              valor = digitos[i] * 2;
              if (valor > 9)
              {
                  valor = valor - 9;
              }
          }
          sumatotal += valor;
      }

      let digito_verificador = sumatotal % 10;

      if (digito_verificador != 0)
      {
          digito_verificador = 10 - (sumatotal % 10);
      }

      if (digitos[10] == digito_verificador) {
        return true;
      }
    }
  }
  return false;
}
}
