import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import * as MenuAction from 'src/app/modules/_global/ngrx/actions/menu.action';
import * as LoginAction from 'src/app/modules/_global/ngrx/actions/login.action';
import { Rutas } from 'src/app/modules/_global/ultil/rutas';

@Component({
  selector: 'app-menu-empresa',
  templateUrl: './menu-empresa.component.html',
  styleUrls: ['./menu-empresa.component.scss']
})
export class MenuEmpresaComponent implements OnInit, OnDestroy {
  usuarioSubscripction: Subscription = new Subscription();
  empresaListSubscription: Subscription = new Subscription();
  loginState: any;
  usuario: any;
  empresaList = [];
  empresaSelected: any;

  constructor(
    private store: Store<any>,
    private router: Router
  ) {}

  ngOnInit() {
    this.store.dispatch(MenuAction.getEmpresaList());
    this.usuarioSubscripction = this.store.select((state) => state?.menu?.Usuario).subscribe(Usuario => {
      if (Usuario != undefined && Usuario != null){
        this.usuario = {...Usuario};
      }
    });
    this.empresaListSubscription = this.store.select((state) => state?.menu?.EmpresaList).subscribe(EmpresaList => {
      if(EmpresaList != undefined && EmpresaList != null){
        this.empresaList = [...EmpresaList];
        this.validateEmpresaPreSelected();
      }
    });
  }

  ngOnDestroy(){
    this.usuarioSubscripction.unsubscribe();
    this.empresaListSubscription.unsubscribe();
  }

  validateEmpresaPreSelected(){
    try {
      if(this.empresaList.length > 0){
        const empresaSelectedList = this.empresaList.filter(element => element.selecionado);
        if(empresaSelectedList.length > 0){
          this.empresaSelected = {...empresaSelectedList[0]};
          this.store.dispatch(MenuAction.setEmpresaSelected({datos: this.empresaSelected}));
        }
      }
    } catch (error) {
      console.error('Error MenuEmpresaComponent -> validateEmpresaPreSelected:', error);
    }
  }

  changeEmpresa(empresaSelectedMenu){
    try {
      this.empresaSelected = {...empresaSelectedMenu};
      if(this.usuario != null && this.usuario != undefined){
          setTimeout(() => {
            this.store.dispatch(MenuAction.getCambioEmpresa({
              filter: {
                id_usuario: this.usuario.id_usuario,
                id_empresa:  this.empresaSelected._id
              }
             }));
            this.redirect(this.empresaSelected);
        }, 500);
      }
    } catch (error) {
      console.error('Error MenuEmpresaComponent -> changeEmpresa', error);
    }
  }

  redirect(empresaSelectedMenu){
    setTimeout(() => {
      this.store.dispatch(MenuAction.setEmpresaSelected({datos: empresaSelectedMenu}));
      this.router.navigate([Rutas.INICIO]);
    }, 1000);
  }
}
