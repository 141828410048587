<div class="app-menu-empresa mr-sm-5">
  <div *ngIf="empresaSelected != null && empresaSelected != undefined">
    <div mat-button class="dropdown-toggle" [matMenuTriggerFor]="menuEmpresa" title="{{empresaSelected?.nombre}}">
      <div class="user-avatar" *ngIf="empresaSelected.logo !== undefined && empresaSelected.logo !== null">
        <img [src]="empresaSelected.logo" alt="" width="41" height="41">
      </div>
      <div class="user-avatar" *ngIf="empresaSelected.logo === undefined">
        {{ empresaSelected?.nombre | textSiglas}}
      </div>
      <div class="user-info d-none d-md-block w-100 text-white">
          <div class="user-name dropdown-indicator text-white" matTooltip="{{empresaSelected?.nombre}}">{{ empresaSelected?.nombre }}</div>
      </div>
    </div>
    <mat-menu #menuEmpresa="matMenu">
      <button mat-menu-item title="{{item?.nombre}}" *ngFor="let item of empresaList" (click)="changeEmpresa(item)">
        <span class="{{item.selecionado === true ? 'active' : ''}}"> {{ item?.nombre }} </span>
      </button>
    </mat-menu>
  </div>
</div>
