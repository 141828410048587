<div class="app-reportes-sub-menu">
  <mat-toolbar>
    <mat-toolbar-row *ngIf="!showBtnMenu">
      <div *ngFor="let item of submenuList">
        <div>
          <div class="btn-group mr-2" role="group" aria-label="reporte-opcion">
            <button mat-button class="btn-pr-0" (click)="setReporteLink(item)">
              {{ item?.nombre }}
            </button>
            <button
              mat-icon-button
              class="btn-pl-0"
              [matMenuTriggerFor]="menuAccionesReporte"
            >
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
          </div>
          <mat-menu #menuAccionesReporte="matMenu">
            <button
              mat-menu-item
              (click)="showEditFormulario(item)"
              *ngIf="item?.permisos?.update"
            >
              <mat-icon>create</mat-icon> <span>Editar URL</span>
            </button>
            <!-- <button  mat-menu-item> -->

            <button mat-menu-item (click)="showObservationReport(item)">
              <mat-icon>assignment_late</mat-icon> <span>Observaciones</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <a mat-button class="" aria-label="" *ngIf="loading">
        <span class="visually-hidden mr-2">Cargando</span>
        <div class="spinner-border text-primary" role="status"></div>
      </a>
      <span class="menu-spacer"></span>
      <a mat-button class="" aria-label="" (click)="setObservationReport(item)">
        <mat-icon matToolTip="Actualizar Menú">refresh</mat-icon>
      </a>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="showBtnMenu">
      <button mat-icon-button (click)="toggleMenuLateral()">
        <span class="mr-1">Menú Reporte</span><mat-icon>menu</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <div *ngIf="showMenuLateral && showBtnMenu">
    <div
      class="card no-padding menu-flotante-izq"
      [@openClose]="showMenuLateral ? 'open' : 'closed'"
    >
      <div class="card-header bg-primary">
        <a class="btn btn-group-refresh text-white" (click)="hideMenuLateral()">
          <mat-icon class="mr-1">arrow_back_ios</mat-icon> <span>Ocultar</span>
        </a>
        <span class="menu-spacer"></span>
        <a mat-button class="" aria-label="" (click)="getSubmoduloList()">
          <mat-icon matToolTip="Actualizar Menú">refresh</mat-icon>
        </a>
      </div>
      <div class="card-body">
        <ul class="nav flex-column" *ngIf="submenuList.length > 0">
          <li class="nav-item btn text-left" *ngFor="let item of submenuList">
            <a
              mat-button
              aria-label=""
              *ngIf="item?.permisos?.update === false"
              (click)="setReporteLink(item)"
            >
              {{ item?.nombre ? item?.nombre : "" }}
            </a>
            <a
              mat-button
              [matMenuTriggerFor]="menuAccionesReporteIzq"
              *ngIf="item?.permisos?.update"
              (click)="setReporteLink(item)"
            >
              {{ item?.nombre
              }}<span class="user-name dropdown-indicator text-primary"></span>
            </a>
            <mat-menu #menuAccionesReporteIzq="matMenu">
              <button mat-menu-item>
                <span>Editar URL</span>
              </button>
            </mat-menu>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
