import { Routes } from '@angular/router';
import { FormComponent } from './componets/form/form.component';
import { RecuperarPasswordComponent } from './componets/recuperar-password/recuperar-password.component';

export const LoginRoutes: Routes = [
  {
    path: 'login/form',
    component: FormComponent
  },
  {
    path: 'login/recuperarpaswword',
    component: RecuperarPasswordComponent
  }
];
