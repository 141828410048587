import { createAction, props } from '@ngrx/store';

export const getFileList = createAction(
  '[FILE] [GET] OBTENER ARCHIVOS Y CARPETAS',
  props<{ filter: any }>()
);

export const refreshFileList = createAction(
  '[FILE] [UPDATE] ACTUALIZAR ARCHIVO-CARPETA SELECCIONADO',
  props<{ filter: any }>()
);

export const setFileList = createAction(
  '[FILE] [SET] CARGAR ARCHIVOS Y CARPETAS',
  props<{ fileList: any, folderList: any }>()
);

export const cleanFileList = createAction(
  '[FILE] [CLEAN] LIMPIAR ARCHIVOS Y CARPETAS'
);

export const setFileSelected = createAction(
  '[FILE] [SET] REGISTRAR ARCHIVO-CARPETA SELECCIONADO',
  props<{ fileSelected: any }>()
);

export const cleanFileSelected = createAction(
  '[FILE] [CLEAN] LIMPIAR ARCHIVO-CARPETA SELECCIONADO'
);

export const setShowMenuDer = createAction(
  '[FILE] [SET] REGISTRAR MOSTRAR MENU LATERAL',
  props<{ showMenuDer: boolean }>()
);

export const setError = createAction(
  '[FILE] [ERROR] REGISTAR ERROR',
  props<{ error: any }>()
);

export const getOptionSearchFileList = createAction(
  '[FILE] [GET] OBTENER LISTA DE OPCIONES PARA BUSCAR ARCHIVOS',
  props<{ filter: any }>()
);

export const setOptionSearchFileList = createAction(
  '[FILE] [SET] REGISTRAR LISTA DE OPCIONES PARA BUSCAR ARCHIVOS',
  props<{ optionList: any }>()
);

export const cleanOptionSearchFileList = createAction(
  '[FILE] [CLEAN] LIMPIAR LISTA DE OPCIONES PARA BUSCAR ARCHIVOS'
);

export const setEmpresaSelected = createAction(
  '[FILE] [SET] EMPRESA SELECCIONADA EN EL BUSCADOR',
  props<{ dato: any }>()
);

export const getEmpresaSelected = createAction(
  '[FILE] [GET] EMPRESA SELECCIONADA EN EL BUSCADOR'
);

export const cleanEmpresaSelected = createAction(
  '[FILE] [LIMPIAR] EMPRESA SELECCIONADA EN EL BUSCADOR'
);

export const cleanAll = createAction(
  '[FILE] [LIMPIAR] LIMPIAR TODO EL STATE DE ARCHIVOS Y CARPETAS'
);
