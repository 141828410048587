import { Injectable } from '@angular/core';
import { HttpHandler, HttpRequest, HttpInterceptor } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';

import * as opcionesModal from 'src/app/modules/_global/ultil/opcionesModal';
import { ModalLoginComponent } from 'src/app/modules/_global/componets/modal-login/modal-login.component';
import { Store } from '@ngrx/store';
import * as GridAction from 'src/app/modules/_global/ngrx/actions/dataGrid.action';
import * as MenuAction from 'src/app/modules/_global/ngrx/actions/menu.action';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  login: any;

  constructor(
    private router: Router,
    private store: Store<any>,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError(error => {
        try {
          this.store.dispatch(GridAction.toggleViewLoading({estado: false}));
          this.store.dispatch(MenuAction.setLoading({estado: false}));
          if (error.status === 401) {
            if(error.error.Status != undefined && error.error.Status != null){
              if(error.error.Status === false){
                if(error.error.Message != undefined && error.error.Message != null){
                  let mensaje = '';
                  error.error.Message.forEach(m => {
                    mensaje += m + '</br>';
                  });
                  this.toastr.error(mensaje, 'Error');
                  //console.log("Error req", req);
                }else{
                  this.toastr.error('Usuario no autorizado', 'Error');
                }
              }
            }else{
              this.toastr.error('Usuario no autorizado', 'Error');
            }

          }else if (error.status === 403) {
            this.showModalLogin();
            if(error.error.Status != undefined && error.error.Status != null){
              if(error.error.Status === false){
                if(error.error.Message != undefined && error.error.Message != null){
                  let mensaje = '';
                  error.error.Message.forEach(m => {
                    mensaje += m + '</br>';
                  });
                  this.toastr.error(mensaje, 'Error');
                }else{
                  this.toastr.error('No tiene permisos para acceder a este recurso', 'Error');
                }
              }
            }else{
              this.toastr.error('No tiene permisos para acceder a este recurso', 'Error');
            }
          }else{
            this.toastr.error('Servicio no disponible');
            console.error('SE DETECTO UN ERROR HTTP REQUEST:', error);
          }
        } catch (error) {
          console.error('Error HttpErrorInterceptor:', error);
        }
        return of(error);
      })
    );
  }
  showModalLogin() {
    const dialogRef = this.dialog.open(ModalLoginComponent, {
      disableClose: true,
      autoFocus: true,
      closeOnNavigation: false,
      position: { top: '50px' },
      width: opcionesModal.TAMANIO_MODAL.SM
    });
  }
}
