import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textSiglas'
})
export class TextSiglasPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    try{
      if (value.length > 0){
        const parts = value.split(' ');
        if (parts.length > 1){
          const siglas = parts[0][0].toUpperCase() + parts[1][0].toUpperCase();
          return siglas;
        }else{
          return parts[0][0].toUpperCase();
        }
      }else{
        return 'U';
      }
    }catch(error){
      return 'U';
    }

  }

}
