export const modalCambiarPasswordValidation = {
  password: {
    required: '<b>Contraseña nueva</b> es un campo requerido.',
    blank: '<b>Contraseña nueva</b> no puede estar vacio.'
  },
  passwordConfirm: {
    required: '<b>Confirma la nueva contraseña</b> es un campo requerido.',
    blank: '<b>Confirma la nueva contraseña</b> no puede estar vacio.'
  }
};
