<div class="app-menu-usuarios">
  <div mat-button class="dropdown-toggle" [matMenuTriggerFor]="menuUsuario" title="{{ usuario?.nombre_usuario }}">
    <div class="user-avatar">
      {{ usuario?.nombre_usuario | textSiglas }}
    </div>
    <div class="user-info d-none d-md-block w-100 text-white">
        <div class="user-status text-white">{{empresaSelected?.rol_nombre}}</div>
        <div class="user-name dropdown-indicator text-white">{{usuario?.nombre_usuario}}</div>
    </div>
 </div>
 <mat-menu #menuUsuario="matMenu">
   <div class="col-lg-12">
    <mat-card class="mat-elevation-z">
      <mat-card-header>
        <div mat-card-avatar>
          <span *ngIf="usuario.logo == undefined || usuario.logo == null"  class="fab-siglas mr-sm-2">{{ usuario?.nombre_usuario | textSiglas }}</span>
          <img *ngIf="usuario.logo != undefined || usuario.logo != null" mat-card-image src="{ {usuario?.logo }}" alt="{{ usuario?.nombre_usuario | textSiglas }}">
        </div>
        <mat-card-title>{{  usuario?.nombre_usuario }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-card-subtitle>{{  usuario?.email }}</mat-card-subtitle>
        <mat-divider></mat-divider>
        <mat-card-actions>
          <a mat-menu-item href="usuarios/perfil" >
            <mat-icon>account_circle</mat-icon> <span>Perfil</span>
          </a>
          <a mat-menu-item (click)="salirApp()">
            <mat-icon>exit_to_app</mat-icon>  <span>Salir</span>
          </a>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
   </div>
</mat-menu>
</div><!-- .app-menu-usuario -->
