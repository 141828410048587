import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import * as opcionesModal from '../../../_global/ultil/opcionesModal';
import * as ReporteAction from 'src/app/modules/_global/ngrx/actions/reporte.action';
import * as MenuAction from 'src/app/modules/_global/ngrx/actions/menu.action';
import {FormComponent as FormReporteComponent } from 'src/app/modules/reportes/componets/form/form.component'
import {ObservationComponent } from 'src/app/modules/reportes/componets/observation/observation.component'


@Component({
  selector: 'app-reportes-sub-menu',
  templateUrl: './reportes-sub-menu.component.html',
  styleUrls: ['./reportes-sub-menu.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: window.innerHeight+'px',
        width: '300px',
        opacity: 1
      })),
      state('closed', style({
        height: window.innerHeight+'px',
        width: '100px',
        left: '-350px',
        opacity: 1
      })),
      transition('open => closed', [
        animate('0.25s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ]
})
export class ReportesSubMenuComponent implements OnInit , OnDestroy{

  empresaSelectedSubscription = new Subscription();
  menuSelectedSubscription = new Subscription();
  submenuListSubcription = new Subscription();
  loadingSubcription = new Subscription();

  empresaSelected: any = null;
  menuSelected: any;

  submenuList = [];
  loading = true;

  showBtnMenu = false;
  showMenuLateral = false;

  idModulo:any;
  idEmpresa:any;
  observationReport:any;



  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    this.validShowMenuLateral();
  }

  constructor(
    private store: Store<any>,
    public dialog: MatDialog,
    private toastr: ToastrService
  ) {
    this.store.dispatch(MenuAction.cleanSubmenuList());
    this.empresaSelectedSubscription = this.store.select((state) => state?.menu?.EmpresaSelected).subscribe(EmpresaSelected => {
      if(EmpresaSelected != undefined && EmpresaSelected != null){
        this.empresaSelected = {...EmpresaSelected};
      }
    });
    this.menuSelectedSubscription = this.store.select((state) => state?.menu?.MenuSelected).subscribe(MenuSelected => {
      if(MenuSelected != undefined && MenuSelected != null){
        this.menuSelected = {...MenuSelected};
      }
    });
    this.submenuListSubcription = this.store.select((state) => state?.menu?.SubmenuList).subscribe(SubmenuList => {
      this.submenuList = [];
      if(SubmenuList != undefined && SubmenuList != null){
        this.submenuList = [...SubmenuList];
        this.sortSubmoduloList();
      }
    });
    this.loadingSubcription = this.store.select((state) => state?.menu?.LoadingSubmenuList).subscribe(LoadingSubmenuList => {
      if(LoadingSubmenuList != undefined && LoadingSubmenuList != null){
        this.loading = LoadingSubmenuList;
      }
    });
    setTimeout(() => {
      if(this.submenuList.length === 0){
        this.getSubmoduloList();
      }
    }, 1000);
  }

  ngOnInit() {
    this.validShowMenuLateral();
  }

  ngOnDestroy(){
    this.menuSelectedSubscription.unsubscribe();
    this.submenuListSubcription.unsubscribe();
    this.loadingSubcription .unsubscribe();
    this.store.dispatch(MenuAction.cleanSubmenuList());
  }

  sortSubmoduloList(){
    if(this.submenuList.length > 0){
      if(this.submenuList[0].orden != undefined && this.submenuList[0].orden != null){
        this.submenuList.sort(function(a,b){
          if(a.orden < b.orden){
            return -1;
          }else{
            return 1;
          }
        });
      }
    }
  }

  
  getSubmoduloList(){
    try {
      this.submenuList = [];
      this.store.dispatch(MenuAction.cleanSubmenuList());
      if(this.menuSelected != undefined && this.menuSelected != null){
        this.store.dispatch(MenuAction.getSubmenuList({
          filter: [
            'filter[where][id_padre]=' + this.menuSelected._id,
            'filter[where][id_empresa]=' + this.empresaSelected._id,
            'filter[where][id_grupo]=' + this.empresaSelected.grupo
          ]
        }));
      }else{
        this.toastr.clear();
        this.toastr.info('Estamos obteniendo menú configuraciones');
        setTimeout(() => {
          if(this.menuSelected != undefined && this.menuSelected != null){
            this.store.dispatch(MenuAction.getSubmenuList({
              filter: [
                'filter[where][id_padre]=' + this.menuSelected._id,
                'filter[where][id_empresa]=' + this.empresaSelected._id,
                'filter[where][id_grupo]=' + this.empresaSelected.grupo
              ]
            }));
          }
        }, 1000);
      }
    } catch (error) {
      console.error('Error MenuModulosComponent -> getSubmoduloList:', error);
    }
  }
  
  
  setReporteLink(modulo){
    this.store.dispatch(ReporteAction.setLinkReporte({link: modulo}));

  }
  
  
  hideMenuLateral(){
    try {
      this.showMenuLateral = false;
    } catch (error) {
      console.error('Error SubMenuHorizontalComponent -> hideMenuLateral:', error);
    }
  }

  toggleMenuLateral(){
    try {
      this.showMenuLateral = !this.showMenuLateral;
    } catch (error) {
      console.error('Error SubMenuHorizontalComponent -> toggleMenuLateral:', error);
    }
  }

  validShowMenuLateral(){
    try {
      const widthWindows = window.innerWidth;
      if(widthWindows <= 730){
        this.showBtnMenu = true;
      }else{
        this.showBtnMenu = false;
        this.showMenuLateral = false;
      }
    } catch (error) {
      console.error('Error ReportesSubMenuComponent -> validShowMenuLateral:', error);
    }
  }

  showEditFormulario(reporte){
    this.dialog.open(FormReporteComponent, {
      disableClose: true,
      autoFocus: true,
      closeOnNavigation: false,
      position: { top: '100px' },
      width: opcionesModal.TAMANIO_MODAL.SM,
      data: {
        item: reporte,
        id_empresa: 1
      }
    });
  }

    showObservationReport(modulo){
    this.idModulo = modulo._id;
    this.idEmpresa = this.empresaSelected._id

    
    this.dialog.open(ObservationComponent, {
      disableClose: true,
      autoFocus: true,
      closeOnNavigation: false,
      position: { top: '100px' },
      width: opcionesModal.TAMANIO_MODAL.SM,
      data: {
        item: modulo,
        id_empresa: this.empresaSelected._id
      }
    });
  }

}
