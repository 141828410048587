import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { FormComponent as ModalMiEmpresa} from 'src/app/modules/empresas/componets/form/form.component';
import * as opcionesModal from 'src/app/modules/_global/ultil/opcionesModal';
import * as MenuAction from 'src/app/modules/_global/ngrx/actions/menu.action';
@Component({
  selector: 'app-menu-principal',
  templateUrl: './menu-principal.component.html',
  styleUrls: ['./menu-principal.component.scss']
})
export class MenuPrincipalComponent implements OnInit, OnDestroy {
  empresaSelectedSubscription = new Subscription();
  empresaSelected: any;
  constructor(
    private  store: Store<any>,
    public dialog: MatDialog,
  ) {
    this.empresaSelectedSubscription = this.store.select((state) => state?.menu?.EmpresaSelected).subscribe(EmpresaSelected => {
      this.empresaSelected = undefined;
      if(EmpresaSelected != undefined && EmpresaSelected!= null){
        this.empresaSelected = {...EmpresaSelected};
        if(this.empresaSelected != undefined && this.empresaSelected != null){
          this.empresaSelected.fecha_inicio_operaciones = moment(this.empresaSelected.fecha_inicio_operaciones).format('DD/MM/yyyy');
        }
      }
    });
   }

  ngOnInit() {

  }
  ngOnDestroy(){
    this.empresaSelectedSubscription.unsubscribe();
  }
  showVerFormulario(){
    this.dialog.open(ModalMiEmpresa, {
      disableClose: true,
      autoFocus: true,
      closeOnNavigation: false,
      position: { top: '100px' },
      width: opcionesModal.TAMANIO_MODAL.LG,
      data: {
        id: this.empresaSelected._id,
        accion: 'VER'
      }
    });
  }
}
