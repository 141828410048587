<div class="app-empresas-form">
  <div mat-dialog-title class="titulo-dialog">
    <button [mat-dialog-close]="true" class="close" mat-icon-button><em class="icon ni ni-cross-sm"></em></button>
    <div class="titulo" *ngIf="data.accion == ACCIONES_FORMULARIO.CREAR" ><span class="icono"><strong><em class="icon ni ni-plus-c"></em></strong></span> <span class="titulo">Crear Empresas</span></div>
    <div class="titulo" *ngIf="data.accion == ACCIONES_FORMULARIO.VER" ><span class="icono"><strong><em class="icon ni ni-eye"></em></strong> </span> <span class="titulo">Ver Empresas</span></div>
    <div class="titulo" *ngIf="data.accion == ACCIONES_FORMULARIO.EDITAR" ><span class="icono"><strong><em class="icon ni ni-edit"></em></strong> </span> <span class="titulo">Editar Empresas</span></div>
  </div>
  <mat-dialog-content>
    <form [formGroup]="formGroup">
      <div class="row mb-3">
        <div class="col-12">
          <label><strong>Información de la Empresa</strong></label>
          <mat-divider></mat-divider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Razón social:</mat-label>
            <input matInput required formControlName="razon_social" name="razon_social" cdkFocusInitial *ngIf="data.accion != ACCIONES_FORMULARIO.VER">
            <input matInput required formControlName="razon_social" name="razon_social" *ngIf="data.accion == ACCIONES_FORMULARIO.VER">
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field>
            <mat-label>Pais:</mat-label>
            <mat-select required formControlName="pais" name="pais"
            [disabled]="data.accion === ACCIONES_FORMULARIO.VER ? true : false"
            (selectionChange)="changePaisSelected($event)">
              <mat-option value="{{item?.pais}}" *ngFor="let item of paisList">
                {{item?.pais}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field>
            <mat-label>Tipo de documento:</mat-label>
            <mat-select required formControlName="tipo_identificacion" name="tipo_identificacion"
            [disabled]="data.accion === ACCIONES_FORMULARIO.VER ? true : false">
              <mat-option value="RUC">RUC</mat-option>
              <mat-option value="IDENTIFICACION">Identificación</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Num. Identificación:</mat-label>
            <input matInput required formControlName="ruc" name="ruc" maxlength="13" appMaskIntegerPositivo>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>RAET:</mat-label>
            <input matInput required appMaskIntegerPositivo formControlName="raet" name="raet">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Expediente:</mat-label>
            <input matInput required appMaskIntegerPositivo formControlName="expediente" name="expediente">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Fecha Inicio Operaciones (dia/mes/año) :</mat-label>
            <input matInput [matDatepicker]="pickerFechaInicioOperaciones" required appMaskDate formControlName="fecha_inicio_operaciones" name="fecha_inicio_operaciones"
            [disabled]="data.accion === ACCIONES_FORMULARIO.VER ? true : false">
            <mat-datepicker-toggle matSuffix [for]="pickerFechaInicioOperaciones"></mat-datepicker-toggle>
            <mat-datepicker #pickerFechaInicioOperaciones></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <mat-form-field>
            <mat-label>Teléfono:</mat-label>
            <input matInput formControlName="telefono" name="telefono" maxlength="13" appMaskIntegerPositivo>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field>
            <mat-label>Dirección:</mat-label>
            <input matInput formControlName="direccion" name="direccion">
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field>
            <mat-label>Sitio Web:</mat-label>
            <input matInput type="url" formControlName="sitio_web" name="sitio_web">
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field>
            <mat-label>Email empresarial:</mat-label>
            <input matInput type="email" formControlName="email_empresarial" name="email_empresarial">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 col-4"
        *ngIf="urlLogo == undefined && urkLogoHttp == null && data.accion != ACCIONES_FORMULARIO.VER">
          <div class="file-drop-area text-center" matTooltip="logo">
            <p class="col-lg-12">Logo</p>
            <input #fileLogo class="file-input" type="file" (change)="fileChangeEvent($event.target.files)">
          </div>
        </div>
        <div class="col-md-2 col-4 text-center"
        *ngIf="(urkLogoHttp != undefined) && data.accion != ACCIONES_FORMULARIO.VER">
          <div class="border-img">
            <img [src]="urkLogoHttp" width="70" height="70" (click)="fileLogo.click()">
            <input #fileLogo class="file-input" type="file" formControlName="logo" (change)="fileChangeEvent($event.target.files)">
          </div>
        </div>
        <div class="col-md-2 col-4 text-center"
        *ngIf="urlLogo != undefined && urlLogo != undefined && data.accion != ACCIONES_FORMULARIO.VER">
          <div class="border-img">
            <img [src]="urlLogo" width="70" height="70" (click)="fileLogo.click()">
            <input #fileLogo class="file-input" type="file" formControlName="logo" (change)="fileChangeEvent($event.target.files)">
          </div>
        </div>
        <div class="col-md-2 col-4 text-center"
        *ngIf="data.accion === ACCIONES_FORMULARIO.VER">
          <div class="border-img">
            <img [src]="urkLogoHttp" disabled width="70" height="70">
          </div>
        </div>
        <div class="col-md-10 col-8">
          <mat-form-field>
            <mat-label>Descripción:</mat-label>
            <textarea matInput formControlName="descripcion" name="descripcion"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-3 nt-5 mb-3">
        <div class="col-12">
          <label><strong>Información del Representante Legal</strong></label>
          <mat-divider></mat-divider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Representante:</mat-label>
            <input matInput required formControlName="representante" name="representante">
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field>
            <mat-label>Pais:</mat-label>
            <mat-select required formControlName="representante_pais" name="representante_pais"
            [disabled]="data.accion === ACCIONES_FORMULARIO.VER ? true : false"
            (selectionChange)="changeRepresentantePaisSelected($event)">
              <mat-option value="{{item?.pais}}" *ngFor="let item of paisList">
                {{item?.pais}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field>
            <mat-label>Tipo de documento:</mat-label>
            <mat-select required formControlName="representante_tipo_identificacion" name="representante_tipo_identificacion"
            [disabled]="data.accion === ACCIONES_FORMULARIO.VER ? true : false">
              <mat-option value="RUC">Cédula</mat-option>
              <mat-option value="IDENTIFICACION">Identificación</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Num. Identificación:</mat-label>
            <input matInput required appMaskIntegerPositivo formControlName="representante_identificacion" name="representante_identificacion">
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [disabled]="disabledBtn" *ngIf="data.accion != ACCIONES_FORMULARIO.VER" mat-raised-button class="btn-primary" (click)="guardarFormulario()">{{ data.accion === ACCIONES_FORMULARIO.CREAR ? 'Crear' : 'Guardar'}}</button>
    <button *ngIf="data.accion == ACCIONES_FORMULARIO.VER && permisoUpdate" mat-raised-button class="btn-primary" (click)="showEditarFormulario()">Editar</button>
    <button [disabled]="disabledBtn" mat-raised-button mat-dialog-close class="btn-danger">Cancelar</button>
  </mat-dialog-actions>
</div>

