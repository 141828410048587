import { createAction, props } from '@ngrx/store';

export const clear = createAction(
  '[DATAGRID] [CLEAR] LIMPIAR TODOS LOS DATOS'
);

export const getDatos = createAction(
  '[DATAGRID] [GET] OBTENER DATOS',
  props<{ modulo: string, filtros?: string[] }>()
);

export const setDatos = createAction(
  '[DATAGRID] [SET] CARGAR DATOS',
  props<{ datos: any }>()
);

export const cleanDatos = createAction(
  '[DATAGRID] [CLEAN] LIMPIAR DATOS'
);

export const toggleViewLoading = createAction(
  '[DATAGRID] [LOADING] REGISTRAR ESTADO VER CARGANDO LOADING',
  props<{ estado: boolean }>()
);

export const setRespuestaWebApi = createAction(
  '[DATAGRID] [SET] CARGAR RESPUESTA WEB API',
  props<{ respuestaWebApi: any, accion: string }>()
);

export const setDatosSeleccionados = createAction(
  '[DATAGRID] [SET] CARGAR DATOS SELECCIONADOS',
  props<{ seleccionados: any }>()
);

export const setError = createAction(
  '[DATAGRID] [SET] ERROR',
  props<{ error: any }>()
);

export const deleteDatos = createAction(
  '[DATAGRID] [DELETE] ELIMINAR DATOS',
  props<{ modulo: string, filtrosDelete: string[] }>()
);

