import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as uuid from 'uuid';

import {environment } from 'src/environments/environment';
import { Rutas } from 'src/app/modules/_global/ultil/rutas';
import { Blank } from 'src/app/validators/blank.validator';
import * as LoginAction from 'src/app/modules/_global/ngrx/actions/login.action';
import { ErrorValidator } from 'src/app/validators/show-error.validator';

import { formValidation } from '../../validators/form.validators';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {

  appName = environment.appName;

  formGroup: FormGroup  = this.fb.group({
    usuario: ['', [Validators.required ,Blank]],
    clave: ['', [Validators.required ,Blank]]
  });
  formId = 'FromLogin-' + uuid.v4();

  loginSubscription: Subscription = new Subscription();
  loginState: any;

  currentDate = moment().format('yyyy');

  constructor(
    private fb: FormBuilder,
    private store: Store<any>,
    private router: Router,
    private showErrorValidator: ErrorValidator
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(LoginAction.get());
    this.loginSubscription = this.store.select((state) => state?.login?.Respuesta).subscribe((Respuesta) => {
      if (Respuesta != undefined){
        this.loginState = {...Respuesta};
        this.validateAuthentication();
      }
    });
  }

  ngOnDestroy(){
    this.loginSubscription.unsubscribe();
  }

  startForm(){
    this.formGroup = this.fb.group({
      usuario: ['', [Validators.required ,Blank]],
      clave: ['', [Validators.required ,Blank]]
    });
  }

  submitForm(): void{
    // Capturo los datos del formulario
    if (this.validateForm()){
      const userForm = this.formGroup.value.usuario;
      const passwordform = this.formGroup.value.clave;
      //NGRX: Validar Acceso
      this.store.dispatch(LoginAction.validateAccess({user: userForm, password: passwordform}));
    }
  }

  validateForm(): boolean{
    if (this.formGroup.invalid){
      this.showErrorValidator.show(this.formGroup.controls, formValidation);
      return false;
    }
    return true;
  }

  validateAuthentication(){
    //Validar si el usuario tiene datos de sesión
    if (this.loginState != undefined && this.loginState != null){
      if(this.loginState.Status != undefined && this.loginState.Status != null){
        if(this.loginState.Status){
          this.router.navigate([Rutas.INICIO]);
        }
      }
    }
  }

}
