<div class="app-modal-cambiar-password">
  <mat-dialog-content>
    <a class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <em class="icon ni ni-cross"></em>
    </a>
    <h5 class="modal-title">Cambio de Contraseña</h5>
    <mat-divider></mat-divider>
    <div class="card-inner">
      <div class="card-head">
        <p class="card-title">Hola {{usuario?.nombreCompleto}},</p>
        <p>Completa los siguientes campos para cambiar la contraseña:</p>
      </div>
      <form [formGroup]="formGroup">
        <div class="form-group">
          <label class="form-label" for="full-name">Contraseña nueva</label>
            <div class="form-control-wrap">
              <input type="password" class="form-control" placeholder="Escriba su nueva contraseña" autocomplete="off"
                formControlName="password">
            </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="email-address">Confirma la nueva contraseña</label>
            <div class="form-control-wrap">
              <input type="password" class="form-control" placeholder="Repita su nueva contraseña" autocomplete="off"
                formControlName="passwordConfirm">
            </div>
        </div>
      </form>
    </div>
    <mat-divider></mat-divider>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="warn" (click)="closeModal()">Cancelar</button>
    <button mat-raised-button color="primary" (click)="cambiarPasswordService()">Cambiar contraseña</button>
  </mat-dialog-actions>
</div>
