export const formValidation = {
  ruc: {
    required: '<b>RUC</b> es un campo requerido.',
    blank: '<b>RUC</b> no puede estar vacio.'
  },
  razon_social: {
    required: '<b>Razón social</b> es un campo requerido.',
    blank: '<b>Razón social</b> no puede estar vacio.'
  },
  representante: {
    required: '<b>Representante:</b> es un campo requerido.',
    blank: '<b>Representante:/b> no puede estar vacio.'
  },
  pais: {
    required: '<b>Pais:</b> de la empresa es un campo requerido.',
    blank: '<b>Pais:/b> de la empresa no puede estar vacio.'
  },
  tipo_identificacion: {
    required: '<b>Tipo de documento:</b> de la empresa es requirido es un campo requerido.',
    blank: '<b>Tipo de documento:/b> no puede estar vacio.'
  },
  raet: {
    required: '<b>RAET:</b> es un campo requerido.',
    blank: '<b>RAET:/b> no puede estar vacio.'
  },
  expediente: {
    required: '<b>Expediente:</b> es un campo requerido.',
    blank: '<b>Expediente:/b> no puede estar vacio.'
  },
  representante_pais:{
    required: '<b>Pais:</b> del representante es un campo requerido.',
  },
  representante_tipo_identificacion: {
    required: '<b>Tipo de documento:</b> del representante es un campo requerido.'
  },
  representante_identificacion: {
    required: '<b>Num. Identificación:</b> del representante es un campo requerido.',
  },
  fecha_inicio_operaciones: {
    required: '<b>Fecha Inicio Operaciones:</b> es un campo requerido.',
    blank: '<b>Fecha Inicio Operaciones:/b> no puede estar vacio.'
  }
};
