import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import * as LoginAction from 'src/app/modules/_global/ngrx/actions/login.action';
import * as MenuAction from 'src/app/modules/_global/ngrx/actions/menu.action';
@Injectable({
  providedIn: 'root'
})

export class LocalStoreService {

  localStorage$ = new ReplaySubject(1);

  constructor(
    private store: Store<any>
  ) {
    this.loadStore();
  }

  loadStore(){
    let item =JSON.parse(localStorage.getItem('login'));
    if(item != undefined && item != null){
      this.store.dispatch(LoginAction.set({respuesta: {...item}}));
    }else{
      this.remove('login');
      this.store.dispatch(LoginAction.clean());
    }
    //MENULIST
    item =JSON.parse(localStorage.getItem('MenuList'));
    if(item != undefined && item != null){
      this.store.dispatch(MenuAction.setMenu({datos: [...item]}));
    }else{
      this.remove('MenuList');
      this.store.dispatch(MenuAction.cleanMenu());
    }
    //SUBMENULIST
    item =JSON.parse(localStorage.getItem('SubmenuList'));
    if(item != undefined && item != null){
      this.store.dispatch(MenuAction.setSubmenuList({datos: [...item]}));
    }else{
      this.remove('SubmenuList');
      this.store.dispatch(MenuAction.cleanSubmenuList());
    }
    //EMPRESA SELECTED
    item =JSON.parse(localStorage.getItem('EmpresaSelected'));
    if(item != undefined && item != null){
      this.store.dispatch(MenuAction.setEmpresaSelected({datos: {...item}}));
    }else{
      this.remove('EmpresaSelected');
      this.store.dispatch(MenuAction.cleanEmpresaSelected());
    }
    //MENU SELECTED
    item =JSON.parse(localStorage.getItem('MenuSelected'));
    if(item != undefined && item != null){
      this.store.dispatch(MenuAction.setMenuSelected({datos: {...item}}));
    }else{
      this.remove('MenuSelected');
      this.store.dispatch(MenuAction.cleanMenuSelected());
    }
  }

  get(key: string): Observable<any> {
    const item =JSON.parse(localStorage.getItem(key));
    this.localStorage$.next(item);
    this.localStorage$.complete();
    return this.localStorage$;
  }

  set(key: string, value: any) {
    this.remove(key);
    localStorage.setItem(key, JSON.stringify(value));
    this.localStorage$.next(true);
    this.localStorage$.complete();
    return this.localStorage$;
  }

  remove(key: string) {
    localStorage.removeItem(key);
    this.localStorage$.next(true);
    this.localStorage$.complete();
    return this.localStorage$;
  }

  removeAll() {
    localStorage.clear();
    this.localStorage$.next(true);
    this.localStorage$.complete();
    return this.localStorage$;
  }

  get isLocalStorageSupported(): boolean {
    return !! localStorage
  }

  validateAuthentication(): boolean{
    if (this.isLocalStorageSupported) {
      const item =JSON.parse(localStorage.getItem('login'));
      if(item != undefined){
        if(item.Status != undefined){
          if(item.Status)
          {
            return true;
          }
        }
      }
    }
    return false;
  }

}
