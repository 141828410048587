import { EffectsBase } from './EffectsBase';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { LocalStoreService } from 'src/app/modules/_global/service/local-store/local-store.service';
import { LoginService } from 'src/app/modules/_global/service/login/login.service';
import * as MenuAction from 'src/app/modules/_global/ngrx/actions/menu.action';
import * as LoginAction from 'src/app/modules/_global/ngrx/actions/login.action';
import * as FileAction from 'src/app/modules/_global/ngrx/actions/file.action';
import { MenuService } from 'src/app/modules/_global/service/menu/menu.service';


@Injectable({
  providedIn: 'root'
})
export class MenuEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private menuService: MenuService,
    private effectsBase: EffectsBase,
    private localStoreService: LocalStoreService,
    private loginService: LoginService
  ) {}

  //EMPRESA LIST
  GetEmpresaList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuAction.getEmpresaList),
      exhaustMap(action =>
        this.localStoreService.get('login').pipe(
          map((resp: any) => {
            try {
              const empresaList = this.effectsBase.GetEmpresaList(resp);
              return MenuAction.setEmpresaList( { datos: [...empresaList]});
            } catch (error) {
              return MenuAction.setError({ error: error });
            }
          }),
          catchError(error => {
            return of(MenuAction.setError({ error: error }));
          })
        )
    ))
  );

  //EMPRESA SELECCIONADA
  SetEmpresaSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuAction.setEmpresaSelected),
      tap((action) => {
        this.localStoreService.set('EmpresaSelected', action.datos);
      })
    ),
    { dispatch: false }
  );

  //MENU PRINCIPAL
  GetMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuAction.getMenu),
      exhaustMap(action =>
        this.localStoreService.get('login').pipe(
          map((resp: any) => {
            try {
              const moduloList = this.effectsBase.GetModuloList(resp);
              this.localStoreService.set('MenuList', moduloList);
              return MenuAction.setMenu( { datos: [...moduloList]});
            } catch (error) {
              return MenuAction.setError({ error: error });
            }
          }),
          catchError(error => {
            return of(MenuAction.setError({ error: error }));
          })
        )
    ))
  );

  //LIMPIAR MENU
  CleanMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuAction.cleanMenu),
      tap((action) => {
        this.localStoreService.remove('MenuList');
      })
    ),
    { dispatch: false }
  );
  //GET MENU SELECCIONADO
  GetMenuSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuAction.getMenuSelected),
      exhaustMap(action =>
        this.localStoreService.get('MenuSelected').pipe(
          map((resp: any) => {
            try {
              return MenuAction.setMenuSelected( { datos: {...resp}});
            } catch (error) {
              return MenuAction.setError({ error: error });
            }
          }),
          catchError(error => {
            return of(MenuAction.setError({ error: error }));
          })
        )
    ))
  );

  //MENU SELECCIONADO
  SetMenuSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuAction.setMenuSelected),
      exhaustMap(action =>
        this.localStoreService.set('MenuSelected', action.datos).pipe(
          map((resp: any) => {
            try {
              return MenuAction.setLoadingMenuSelected({estado: false});
            } catch (error) {
              return MenuAction.setError({ error: error });
            }
          }),
          catchError(error => {
            return of(MenuAction.setError({ error: error }));
          })
        )
    ))
  );

  //MENU USUARIO
  GetUsuario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuAction.getUsuario),
      exhaustMap(action =>
        this.localStoreService.get('login').pipe(
          map((resp: any) => {
            try {
              const usuario = this.effectsBase.GetUsuario(resp);
              return MenuAction.setUsuario( { dato: usuario });
            } catch (error) {
              return MenuAction.setError({ error: error });
            }
          }),
          catchError(error => {
            return of(MenuAction.setError({ error: error }));
          })
        )
    ))
  );

  //SUBMENU
  GetSubmenuList$ = createEffect(() =>
  this.actions$.pipe(
    ofType(MenuAction.getSubmenuList),
    exhaustMap(action =>
      this.menuService.getSubmodule(action.filter).pipe(
        map((resApi: any) => {
          try {
            const res = this.effectsBase.TransformarRespuestaWebApi(resApi);
            const submenu = this.effectsBase.GetSubmenu(resApi);
            this.localStoreService.set('SubmenuList',[...submenu]);
            return MenuAction.setSubmenuList({datos: [...submenu]});
          } catch (error) {
            return MenuAction.setError({ error: error });
          }
        }),
        catchError(error => {
          return of(MenuAction.setError({ error: error }));
        })
      )
    ))
  );

  //getBuscardorEmpresas
  GetBuscardorEmpresas$ = createEffect(() =>
  this.actions$.pipe(
    ofType(MenuAction.getBuscardorEmpresas),
    exhaustMap(action =>
      this.menuService.getBuscadorEmpresas(action.filter).pipe(
        map((resApi: any) => {
          try {
            const res = this.effectsBase.TransformarRespuestaWebApi(resApi);
            const submenu = this.effectsBase.GetSubmenu(resApi);
            this.localStoreService.set('SubmenuList',[...submenu]);
            return MenuAction.setSubmenuList({datos: [...submenu]});
          } catch (error) {
            return MenuAction.setError({ error: error });
          }
        }),
        catchError(error => {
          return of(MenuAction.setError({ error: error }));
        })
      )
    ))
  );

  //LIMPIAR SUBMENU
  CleanSubmenuList$ = createEffect(() =>
  this.actions$.pipe(
    ofType(MenuAction.cleanSubmenuList),
    exhaustMap(action =>
      this.localStoreService.remove('SubmenuList').pipe(
        map((resApi: any) => {
          try {
            return MenuAction.setLoadingSubMenuList({estado: false});
          } catch (error) {
            return MenuAction.setError({ error: error });
          }
        }),
        catchError(error => {
          return of(MenuAction.setError({ error: error }));
        })
      )
    ))
  );

  //GET LOGIN POR CAMBIO DE EMPRESA SELECCIONADA
  GetCambioEmpresa$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuAction.getCambioEmpresa),
      exhaustMap(action =>
        this.loginService.getPermisosByUsuarioEmpresa(action.filter).pipe(
          map((resApi: any) => {
            try{
              this.store.dispatch(MenuAction.cleanAll());
              this.store.dispatch(FileAction.cleanAll());
              this.store.dispatch(LoginAction.clean());
              const respuesta = this.effectsBase.TransformarRespuestaWebApi(resApi);
              return LoginAction.setStore({ respuesta: respuesta});
            }catch(errorRes){
              return LoginAction.setError({ error: errorRes });
            }
          }),
          catchError(errorRes => {
            return of(LoginAction.setError({ error: errorRes }));
          })
        )
    ))
  );

  //CARGAR SUBMENU SELECTED
  SetSubmenuSelected$ = createEffect(() =>
  this.actions$.pipe(
    ofType(MenuAction.setSubmenuSelected),
    exhaustMap(action =>
      this.localStoreService.set('SubmenuSelected', action.dato).pipe(
        map((resApi: any) => {
          try {
            return MenuAction.setLoadingSubMenuList({estado: false});
          } catch (error) {
            return MenuAction.setError({ error: error });
          }
        }),
        catchError(error => {
          return of(MenuAction.setError({ error: error }));
        })
      )
    ))
  );

  //LIMPIAR USUARIO
  CleanUsuario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuAction.cleanUsuario),
      tap((action) => {
        this.localStoreService.remove('Usuario');
      })
    ),
    { dispatch: false }
  );

  //LIMPIAR MENU
  CleanAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuAction.cleanAll),
      tap((action) => {
        this.localStoreService.remove('EmpresaSelected');
        this.localStoreService.remove('MenuList');
        this.localStoreService.remove('MenuSelected');
        this.localStoreService.remove('SubmenuList');
        this.localStoreService.remove('SubmenuSelected');
      })
    ),
    { dispatch: false }
  );

  //ICONO LIST
  GeticonoList$ = createEffect(() =>
  this.actions$.pipe(
    ofType(MenuAction.getIconoList),
    exhaustMap(action =>
      this.menuService.getIconoList().pipe(
        map((resApi: any) => {
          try {
            const res = this.effectsBase.TransformarRespuestaWebApi(resApi);
            return MenuAction.setIconoList({datos: [...res.Data]});
          } catch (error) {
            return MenuAction.setError({ error: error });
          }
        }),
        catchError(error => {
          return of(MenuAction.setError({ error: error }));
        })
      )
    ))
  );
}
