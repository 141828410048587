import { createReducer, on, Action } from '@ngrx/store';
import * as ReporteAction from '../actions/reporte.action';
export interface reporteState {
  Link: string;
  Error: any;
}

export const initialStateReporte: reporteState = {
  Link: null,
  Error: null
};

const _reporteReducer = createReducer(
  initialStateReporte,
  on(ReporteAction.setLinkReporte, (state, {link}) => ({
    ...state,
    Link: link
  })),
  on(ReporteAction.cleanLinkReporte, (state) => ({
    ...state,
    Link: null
  })),
  on(ReporteAction.cleanState, (state) => ({
    ...initialStateReporte
  })),
  on(ReporteAction.setError, (state, {error}) => ({
    ...state,
    Error: error
  }))
);


export function ReporteReducer(state: reporteState, action: Action) {
  return _reporteReducer(state, action);
}

