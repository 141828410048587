import { createReducer, on, Action } from '@ngrx/store';
import * as crudBasicAction from '../actions/dataGrid.action';

export interface State {
  Tabla: any;
}
export const initialState: State = {
  Tabla: {}
};
const _reducer = createReducer(
  initialState,
  on(crudBasicAction.clear, (state) => ({
    ...initialState
  })),
  on(crudBasicAction.getDatos, (state, {modulo, filtros}) => {
    let Tabla = {...state.Tabla};
    Tabla = undefined;
    Tabla = {
      ...state.Tabla,
      modulo,
      accion: 'GET',
      filtros,
      filtrosDelete: undefined,
      datos: undefined,
      respuestaWebApi: undefined,
      error: undefined,
      loading: true
    };
    return {
      ...initialState,
      Tabla
    };
  }),
  on(crudBasicAction.setDatos, (state, { datos}) => {
    let Tabla = {...state.Tabla};
    Tabla = {
      ...state.Tabla,
      accion: 'SET',
      datos,
      loading: false
    };
    return {
      ...state,
      Tabla
    };
  }),
  on(crudBasicAction.cleanDatos, (state) => {
    let Tabla = {...state.Tabla};
    Tabla = {
      ...state.Tabla,
      accion: 'CLEAN',
      datos: undefined
    };
    return {
      ...state,
      Tabla
    };
  }),
  on(crudBasicAction.toggleViewLoading, (state, {estado}) => {
    let Tabla = {...state.Tabla};
    Tabla = {
      ...state.Tabla,
      loading: estado
    };
    return {
      ...state,
      Tabla
    };
  }),
  on(crudBasicAction.setError, (state, { error}) => {
    let Tabla = {...state.Tabla};
    Tabla = {
      ...state.Tabla,
      accion: 'ERROR',
      datos: undefined,
      respuestaWebApi: undefined,
      error
    };
    return {
      ...state,
      Tabla
    };
  }),
  on(crudBasicAction.setRespuestaWebApi, (state, { respuestaWebApi, accion}) => {
    let Tabla = {...state.Tabla};
    Tabla = {
      ...state.Tabla,
      accion,
      respuestaWebApi
    };
    return {
      ...state,
      Tabla
    };
  }),
  on(crudBasicAction.setDatosSeleccionados, (state, { seleccionados}) => {
    let Tabla = {...state.Tabla};
    Tabla =  {
      ...state.Tabla,
      accion: 'SELECCIONADOS',
      seleccionados
    };
    return {
      ...state,
      Tabla
    };
  }),
  on(crudBasicAction.deleteDatos, (state, {modulo, filtrosDelete}) => {
    let Tabla = {...state.Tabla};
    Tabla =  {
      ...state.Tabla,
      accion: 'DELETE',
      modulo,
      filtrosDelete
    };
    return {
      ...state,
      Tabla
    };
  }),
);
export function DataGridReducer(state: State, action: Action) {
  return _reducer(state, action);
}
