<div class="app-login-recuperar-password">
  <!-- content @s -->
  <div class="nk-split nk-split-page nk-split-md">
    <div class="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white">
        <div class="absolute-top-right d-lg-none p-3 p-sm-5">
            <a href="#" class="toggle btn-white btn btn-icon btn-light" data-target="athPromo"><em class="icon ni ni-info"></em></a>
        </div>
        <div class="nk-block nk-block-middle nk-auth-body">
            <div class="brand-logo pb-5">
                <a href="html/index.html" class="logo-link">
                    <img class="logo-light logo-img logo-img-lg" src="./assets/images/logo-blanco.png" srcset="./assets/images/logo-blanco.png 2x" alt="{{ appName }}">
                    <img class="logo-dark logo-img logo-img-lg" src="./assets/images/logo-blanco.png" srcset="./assets/images/logo-blanco.png 2x" alt="{{ appName }}">
                </a>
            </div>
            <div class="nk-block-head">
                <div class="nk-block-head-content">
                    <h5 class="nk-block-title">Recuperación de Contraseña</h5>
                    <div class="nk-block-des">
                        <p>Introduce tu dirección de correo electrónico de recuperación.</p>
                    </div>
                </div>
            </div><!-- .nk-block-head -->
            <form [formGroup]="formGroup" (ngSubmit)="enviarRecuperacionPassowrd()">
                <div class="form-group">
                    <div class="form-label-group">
                        <label class="form-label" for="default-01">Email</label>
                    </div>
                    <input type="text" class="form-control form-control-lg" id="default-01"
                      formControlName="email" name="email"
                      required cdkFocusInitial>
                </div><!-- .foem-group -->
                <div class="form-group">
                    <button class="btn btn-lg btn-primary btn-block">Enviar correo de recuperación</button>
                </div>
            </form><!-- form -->
            <div class="nk-block mt-5">
              <a href="login/index" class="btn btn-lg btn-block"><em class="icon ni ni-arrow-left-circle mr-1"></em> Regresar a inicio de sesión</a>
            </div>
        </div><!-- .nk-block -->
        <div class="nk-block nk-auth-footer">
            <div class="mt-3">
                <p>&copy; {{ fachaActual}} {{ appName }}. Todos los derechos reservados.</p>
            </div>
        </div><!-- .nk-block -->
    </div><!-- .nk-split-content -->
    <div class="nk-split-content nk-split-stretch bg-lighter d-flex toggle-break-lg toggle-slide toggle-slide-right" data-content="athPromo" data-toggle-screen="lg" data-toggle-overlay="true">
        <div class="slider-wrap w-100 w-max-550px p-3 p-sm-5 m-auto">
            <div class="slider-init" data-slick='{"dots":true, "arrows":false}'>
                <div class="slider-item">
                    <div class="nk-feature nk-feature-center">
                        <div class="nk-feature-img">
                            <img class="round" src="./assets/images/slides/promo-a.png" srcset="./assets/images/slides/promo-a2x.png 2x" alt="">
                        </div>
                        <div class="nk-feature-content py-4 p-sm-5">
                            <h4>{{ appName }}</h4>
                            <p>.</p>
                        </div>
                    </div>
                </div><!-- .slider-item -->
            </div><!-- .slider-init -->
            <div class="slider-dots"></div>
            <div class="slider-arrows"></div>
        </div><!-- .slider-wrap -->
    </div><!-- .nk-split-content -->
  </div><!-- .nk-split -->
  <!-- wrap @e -->
  <!--Start of Tawk.to Script-->
<script type="text/javascript">
  var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
  (function(){
  var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
  s1.async=true;
  s1.src='https://embed.tawk.to/6005ea14c31c9117cb6fe9cf/1esbhkojl';
  s1.charset='UTF-8';
  s1.setAttribute('crossorigin','*');
  s0.parentNode.insertBefore(s1,s0);
  })();
  </script>
  <!--End of Tawk.to Script-->
  </div>

