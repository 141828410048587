import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServiceBase {
  public crearUrlParametros(url: string, filtros: string  []){
    if (filtros){
      if(filtros != null ){
        if (filtros.length > 0){
          let filtroUrl = '?';
          let contador = 1;
          filtros.forEach(filtro => {
            if (contador === filtros.length){
              filtroUrl += filtro;
            }else{
              filtroUrl += filtro + '&';
            }
            contador++;
          });
          url += filtroUrl;
        }
      }
    }
    return url;
  }
}
