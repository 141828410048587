<div class="app-menu-principal">
	<mat-toolbar>
    <mat-toolbar-row>
      <app-menu-empresa></app-menu-empresa>
      <app-menu-modulos></app-menu-modulos>
      <button mat-button (click)="showVerFormulario()">
        Mi Empresa
      </button>
      <a mat-button href="https://gemsa360.tawk.help/" target="_black">
        Ayuda
      </a>
      <span class="menu-spacer"></span>
      <app-menu-usuario></app-menu-usuario>
    </mat-toolbar-row>
	</mat-toolbar>
</div>
