import { createReducer, on, Action } from '@ngrx/store';
import * as MenuAction from '../actions/menu.action';

interface menuState{
  EmpresaList: any[];
  EmpresaSelected: any;
  MenuList: any[];
  MenuSelected: any;
  SubmenuList: any[];
  SubmenuSelected: any;
  Usuario: any;
  Filter: any[];
  LoadingEmpresaList: boolean;
  LoadingEmpresaSelected: boolean;
  LoadingMenuList: boolean;
  LoadingMenuSelected: boolean;
  LoadingSubmenuList: boolean;
  LoadingSubmenuSelected: boolean;
  IconoList: any[];
  Error: any;
}

const initialStateMenu: menuState = {
  EmpresaList: null,
  EmpresaSelected: null,
  MenuList: null,
  MenuSelected: null,
  SubmenuList: null,
  SubmenuSelected: null,
  Usuario: null,
  Filter: null,
  LoadingEmpresaList: false,
  LoadingEmpresaSelected: false,
  LoadingMenuList: false,
  LoadingMenuSelected: false,
  LoadingSubmenuList: false,
  LoadingSubmenuSelected: false,
  IconoList: [],
  Error: null,
};

const _menuReducer = createReducer(
  initialStateMenu,
  //EmpresaList
  on(MenuAction.getEmpresaList, (state) => ({
    ...state,
    EmpresaList: [],
    loadingEmpresaList: true,
    Error: null
  })),
  on(MenuAction.setEmpresaList, (state, {datos}) => ({
    ...state,
    EmpresaList: datos,
    loadingEmpresaList: false,
    Error: null
  })),
  on(MenuAction.cleanEmpresaList, (state) => ({
    ...state,
    EmpresaList: [],
    loadingEmpresaList: true,
    Error: null
  })),
  //EmpresaSelected
  on(MenuAction.getEmpresaSelected, (state) => ({
    ...state,
    EmpresaSelected: null,
    LoadingEmpresaSelected: true,
    Error: null
  })),
  on(MenuAction.setEmpresaSelected, (state, {datos}) => ({
    ...state,
    EmpresaSelected: datos,
    LoadingEmpresaSelected: false,
    Error: null
  })),
  on(MenuAction.cleanEmpresaSelected, (state) => ({
    ...state,
    EmpresaSelected: null,
    LoadingEmpresaSelected: true,
    Error: null
  })),
  //MenuList
  on(MenuAction.getMenu, (state) => ({
    ...state,
    MenuList: [],
    LoadingMenuList: true,
    Error: null
  })),
  on(MenuAction.setMenu, (state, { datos }) => ({
    ...state,
    MenuList: datos,
    LoadingMenuList: false
  })),
  on(MenuAction.cleanMenu, (state) => ({
    ...state,
    MenuList: [],
    LoadingMenuList: true
  })),
  //MenuSelected
  on(MenuAction.getMenuSelected, (state) => ({
    ...state,
    MenuSelected: null,
    LoadingMenuSelected: true,
    Error: null
  })),
  on(MenuAction.setMenuSelected, (state, { datos }) => ({
    ...state,
    MenuSelected: datos,
    LoadingMenuSelected: false
  })),
  on(MenuAction.cleanMenuSelected, (state) => ({
    ...state,
    MenuSelected: null,
    LoadingMenuSelected: true
  })),
  on(MenuAction.setLoadingMenuSelected, (state) => ({
    ...state,
    LoadingMenuSelected: false
  })),
  //SubmenuList
  on(MenuAction.getSubmenuList, (state, { filter }) => ({
    ...state,
    Filter: filter,
    SubmenuList: [],
    LoadingSubmenuList: true,
    Error: null
  })),
  on(MenuAction.setSubmenuList, (state, { datos }) => ({
    ...state,
    SubmenuList: datos,
    LoadingSubmenuList: false
  })),
  on(MenuAction.cleanSubmenuList, (state) => ({
    ...state,
    SubmenuList: [],
    LoadingSubmenuList: true
  })),
  on(MenuAction.setLoadingSubMenuList, (state, { estado }) => ({
    ...state,
    LoadingSubmenuList: estado
  })),
  //SubmenuSelected
  on(MenuAction.getSubmenuSelected, (state) => ({
    ...state,
    SubmenuSelected: null
  })),
  on(MenuAction.setSubmenuSelected, (state, { dato }) => ({
    ...state,
    SubmenuSelected: dato
  })),
  on(MenuAction.cleanSubmenuSelected, (state) => ({
    ...state,
    SubmenuSelected: null
  })),
  //Usuario
  on(MenuAction.getUsuario, (state) => ({
    ...state,
    Usuario: null
  })),
  on(MenuAction.setUsuario, (state, { dato }) => ({
    ...state,
    Usuario: dato
  })),
  on(MenuAction.cleanUsuario, (state) => ({
    ...state,
    Usuario: null
  })),
  //Cambio de empresa
  on(MenuAction.getCambioEmpresa, (state, {filter}) => ({
    ...state,
    Filter: filter
  })),
  on(MenuAction.setLoading, (state, { estado }) => ({
    ...state,
    LoadingSubmenuList: estado
  })),
  on(MenuAction.setError, (state, { error }) => ({
    ...state,
    Error: error
  })),
  on(MenuAction.cleanAll, (state) => ({
    ...initialStateMenu
  })),
  //
  on(MenuAction.getBuscardorEmpresas, (state, {filter}) => ({
    ...state,
    Filter: filter,
    SubmenuList: []
  })),
  on(MenuAction.getIconoList, (state) => ({
    ...state,
    IconoList:[]
  })),
  on(MenuAction.setIconoList, (state, {datos}) => ({
    ...state,
    IconoList: datos
  })),
  on(MenuAction.cleantIconoList, (state) => ({
    ...state
  }))
);

export function MenuReducer(state: menuState, action: Action) {
  return _menuReducer(state, action);
}

