import { createAction, props } from '@ngrx/store';

//EMPRESA LIST
export const getEmpresaList = createAction(
  '[MENU] [GET] OBTENER LISTA EMPRESAS'
);

export const setEmpresaList = createAction(
  '[MENU] [SET] REGISTRAR LISTA EMPRESAS',
  props<{ datos: any }>()
);

export const cleanEmpresaList = createAction(
  '[MENU] [CLEAN] LIMPIAR LISTA EMPRESAS'
);

//EMPRESA SELECTED
export const getEmpresaSelected = createAction(
  '[MENU] [GET] OBTENER EMPRESA SELECCIONADA'
);

export const setEmpresaSelected = createAction(
  '[MENU] [SET] REGISTRAR EMPRESA SELECCIONADA',
  props<{ datos: any }>()
);

export const cleanEmpresaSelected = createAction(
  '[MENU] [CLEAN] LIMPIAR EMPRESA SELECCIONADA'
);

//MENU
export const getMenu = createAction(
  '[MENU] [GET] OBTENER MENU'
);

export const setMenu = createAction(
  '[MENU] [SET] REGISTRAR MENU',
  props<{ datos: any }>()
);

export const cleanMenu = createAction(
  '[MENU] [CLEAN] LIMPIAR MENU'
);

//MENU SELECTED
export const getMenuSelected = createAction(
  '[MENU] [GET] OBTENER MENU SELECCIONADO'
);

export const setMenuSelected = createAction(
  '[MENU] [SET] REGISTRAR MENU SELECCIONADO',
  props<{ datos: any }>()
);

export const cleanMenuSelected = createAction(
  '[MENU] [CLEAN] LIMPIAR MENU SELECCIONADO'
);

export const setLoadingMenuSelected = createAction(
  '[MENU] [SET] REGISTRAR LOADING MENUS SELECTED',
  props<{ estado: boolean }>()
);

//SUBMENU
export const getSubmenuList = createAction(
  '[MENU] [GET] OBTENER SUBMENU',
  props<{ filter?: any }>()
);

export const setSubmenuList = createAction(
  '[MENU] [SET] REGISTRAR SUBMENU',
  props<{ datos: any }>()
);

export const cleanSubmenuList = createAction(
  '[MENU] [CLEAN] LIMPIAR SUBMENU'
);

export const setLoadingSubMenuList = createAction(
  '[MENU] [SET] REGISTRAR LOADING SUBMENU',
  props<{ estado: boolean }>()
);

//SUBMENU SELECTED
export const getSubmenuSelected = createAction(
  '[MENU] [GET] OBTENER SUBMENU SELECTED'
);

export const setSubmenuSelected = createAction(
  '[MENU] [SET] REGISTRAR SUBMENU SELECTED',
  props<{ dato: any }>()
);

export const cleanSubmenuSelected = createAction(
  '[MENU] [CLEAN] LIMPIAR SUBMENU SELECTED'
);

//USUARIO
export const getUsuario = createAction(
  '[MENU] [GET] OBTENER USUARIO'
);

export const setUsuario = createAction(
  '[MENU] [SET] REGISTRAR USUARIO',
  props<{ dato: any }>()
);

export const cleanUsuario = createAction(
  '[MENU] [CLEAN] LIMPIAR USUARIO'
);

//CAMBIAR EMPRESA SELECCIONADA
export const getCambioEmpresa = createAction(
  '[MENU] [GET] OBTENER LOGIN POR CAMBIO EMPRESA',
  props<{ filter?: any }>()
);

//LOADING
export const setLoading = createAction(
  '[MENU] [SET] REGISTRAR LOADING',
  props<{ estado: boolean }>()
);

//ERROR
export const setError = createAction(
  '[MENU] [ERROR] REGISTRAR ERROR',
  props<{ error: any }>()
);

//LIMPIAR TODO EL STATE
export const cleanAll = createAction(
  '[MENU] [CLEAN] LIMPIAR MENU STATE'
);

export const getBuscardorEmpresas = createAction(
  '[MENU] [GET] BUSCAR SUBMENU POR EMPRESA, GRUPO Y ID PADRE',
  props<{ filter: any }>()
);

export const getIconoList = createAction(
  '[MENU] [GET] OBTENER LISTA DE ICONOS'
);

export const setIconoList = createAction(
  '[MENU] [SET] CARGAR LISTA DE ICONOS',
  props<{ datos: any }>()
);


export const cleantIconoList = createAction(
  '[MENU] [CLEAN] LIMPIAR LISTA DE ICONOS'
);
