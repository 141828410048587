import { Component, OnDestroy, OnInit } from '@angular/core';
import * as ItemFormAction from 'src/app/modules/_global/ngrx/actions/itemForm.action';
import * as LoginAction from 'src/app/modules/_global/ngrx/actions/login.action';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as uuid from 'uuid';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Blank } from '../../../../validators/blank.validator';
import { modalCambiarPasswordValidation } from '../../validators/modal-cambiar-password.validation';
import { ErrorValidator } from '../../../../validators/show-error.validator';

@Component({
  selector: 'app-cambiar-password',
  templateUrl: './modal-cambiar-password.component.html',
  styleUrls: ['./modal-cambiar-password.component.scss']
})
export class ModalCambiarPasswordComponent implements OnInit, OnDestroy{

  formId = 'MODALCAMCONTR'+uuid.v4();
  formGroup: FormGroup;

  respuestaSubscription: Subscription = new Subscription();
  usuarioSubscription: Subscription = new Subscription();

  usuario: any;

  constructor(
    private store: Store<any>,
    public dialogRef: MatDialogRef<ModalCambiarPasswordComponent>,
    private fb: FormBuilder,
    private showErrorValidator: ErrorValidator,
    private toastr: ToastrService
    ) { }

  ngOnInit() {
    this.iniciarFormulario();
    this.usuarioSubscription = this.store.select((state) => state?.login?.Usuario).subscribe(Usuario => {
      if (Usuario != undefined){
        this.usuario = {...Usuario};
      }
    });
    this.respuestaSubscription = this.store.select((state) => state?.itemForm?.Respuesta[this.formId]).subscribe(Respuesta => {
      if(Respuesta != undefined && Respuesta != null){
        if(Respuesta.Status != undefined && Respuesta.Status != null){
          if(Respuesta.Status === true){
            this.toastr.success('Se actualizo la contraseña correctamente.');
            const formulario = this.obtenerDatosFormulario();
            this.getLogin(Respuesta.Data[0].user_name, formulario.password);
            this.closeModal();
          }else{
            this.toastr.error('No se pudo actualizar la contraseña.');
          }
        }
      }
    });

  }

  ngOnDestroy() {
    this.respuestaSubscription.unsubscribe();
    this.usuarioSubscription.unsubscribe();
    this.store.dispatch(ItemFormAction.clearByFormId({formId: this.formId}));
  }

  iniciarFormulario(){
    this.store.dispatch(ItemFormAction.setInciarForm({formId: this.formId}));
    this.formGroup = this.fb.group({
      password: [null, [Validators.required, Blank]],
      passwordConfirm: [null, [Validators.required, Blank]]
    });
  }

  cambiarPasswordService(){
    if(this.validarFormulario()){
      const datosForm = this.obtenerDatosFormulario();
      this.store.dispatch(ItemFormAction.actulizarDatos({
        modulo: 'usuarios',
        formId: this.formId,
        datosGuardar: datosForm,
        filtros: ['filter[where][_id]=' + this.getUsuarioId()]
      }));
    }
  }

  validarFormulario(){
    if (this.formGroup.invalid){
      this.showErrorValidator.show(this.formGroup.controls, modalCambiarPasswordValidation);
      return false;
    }else{
      return true;
    }
  }

  obtenerDatosFormulario(){
    const formulario = {...this.formGroup.value};
    const datosFormulario = {
      _id: this.getUsuarioId(),
      password: formulario.password,
      cambiar_password: true,
    };
    return datosFormulario;
  }

  getUsuarioId(){
    try{
      if(this.usuario != undefined && this.usuario != null){
        if (this.usuario.id != null && this.usuario.id != undefined){
          return this.usuario.id;
        }
      }
    }catch(error){
      console.error('Error menu usuario getUsuarioId', error);
    }
    return 0;
  }

  getLogin(usuario, clave){
    const datosRespuesta = {
      "user_name": usuario,
      "password": clave
    }
    this.store.dispatch(ItemFormAction.guardarDatos({
      formId: this.formId,
      modulo: 'login',
      datosGuardar: datosRespuesta
    }));
  }

  closeModal(){
    this.dialogRef.close();
  }


}
