export class ConvertirDatos {

  public toastrMensaje(mensajesApi: string[]): string{
    let mensaje = '';
    if (mensajesApi){
      mensajesApi.forEach(m => {
        mensaje += m + '</br>';
      });
    }
    return mensaje;
  }
}
