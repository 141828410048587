import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { ServiceBase } from '../ServiceBase';

@Injectable({
  providedIn: 'root'
})
export class CrudBasicService {

  urlApi = environment.apiUrl;

  constructor(private httpClient: HttpClient, private serviceBase: ServiceBase, private store: Store<any>) {

  }

  getDatos(modulo: string, filtros?: string[]){
    let url = this.urlApi + '/' + modulo;
    url = this.serviceBase.crearUrlParametros(url, filtros);
    return this.httpClient.get(url);
  }

  getDatosById(modulo: string, filtros?: any){
    let url = this.urlApi + '/' + modulo;
    return this.httpClient.get(url);
  }

  postDatos(modulo: string, datos: any){
    const url = this.urlApi + '/' + modulo;
    return this.httpClient.post(url , datos);
  }

  putDatos(modulo: string, datos: any, filtros: string[]){
    let url = this.urlApi + '/' + modulo;
    url = this.serviceBase.crearUrlParametros(url, filtros);
    return this.httpClient.put(url, datos);
  }

  deleteDatos(modulo: string, filtros: string[]){
    let url = this.urlApi + '/' + modulo;
    url = this.serviceBase.crearUrlParametros(url, filtros);
    return this.httpClient.delete(url);
  }

  refrescarLogin(modulo: string, datos: any){
    const url = this.urlApi + '/' + modulo;
    return this.httpClient.post(url , datos);
  }

}
