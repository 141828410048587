import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as uuid from 'uuid';
import * as moment from 'moment';

import {environment } from 'src/environments/environment';
import * as LoginAction from 'src/app/modules/_global/ngrx/actions/login.action';
import * as ItemFormAction from 'src/app/modules/_global/ngrx/actions/itemForm.action';
import { Blank } from 'src/app/validators/blank.validator';
import { ErrorValidator } from 'src/app/validators/show-error.validator';
import { ConvertirDatos } from 'src/app/modules/_global/ultil/ConvertirDatos';
import { modalLoginValidation } from '../../validators/modal-login.validatos';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Rutas } from '../../ultil/rutas';

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss']
})
export class ModalLoginComponent implements OnInit, OnDestroy {
  appName = environment.appName;
  formId = 'ModalLogin-'+uuid.v4();
  convertirDatos = new ConvertirDatos();
  formGroup: FormGroup = this.fb.group({
    usuario: ['', [Validators.required ,Blank]],
    clave: ['', [Validators.required ,Blank]]
  });

  respuestaSubscription: Subscription = new Subscription();
  loginState: any;

  fachaActual = moment();
  constructor(
    private route: Router,
    private fb: FormBuilder,
    private store: Store<any>,
    private showErrorValidator: ErrorValidator,
    public dialogRef: MatDialogRef<ModalLoginComponent>,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.iniciarFormulario();
    this.respuestaSubscription = this.store.select((state) => state?.itemForm?.Respuesta[this.formId]).subscribe(Respuesta => {
      let mensajes = '';
      if(Respuesta != undefined && Respuesta != null){
        if(Respuesta.Messages != undefined && Respuesta.Messages != null){
          mensajes = this.convertirDatos.toastrMensaje(Respuesta.Messages);
        }
        if(Respuesta.Status != undefined && Respuesta.Status != null){
          if(Respuesta.Status === true){
            this.closeModal();
          }else{
            if(mensajes.length === 0){
              mensajes = "Usuario invaliado.";
            }
            this.toastr.error(mensajes);
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.respuestaSubscription.unsubscribe();
    this.store.dispatch(ItemFormAction.clearByFormId({formId: this.formId}));
  }

  iniciarFormulario(){
    this.store.dispatch(ItemFormAction.setInciarForm({formId: this.formId}));
    this.formGroup = this.fb.group({
      usuario: ['', [Validators.required ,Blank]],
      clave: ['', [Validators.required ,Blank]]
    });
  }

  ingresar(): void{
    // Capturo los datos del formulario
    if (this.validarFormulario()){
      const usuarioForm = this.formGroup.value.usuario;
      const claveform = this.formGroup.value.clave;
      const datosFormulario = {
        'user_name': usuarioForm,
        'password': claveform
      }
      this.store.dispatch(ItemFormAction.setRefrescarLogin({
        formId: this.formId,
        modulo: 'login',
        datosGuardar: datosFormulario
      }));
    }
  }

  validarFormulario(): boolean{
    if (this.formGroup.invalid){
      this.showErrorValidator.show(this.formGroup.controls, modalLoginValidation);
      return false;
    }
    return true;
  }

  closeModal(){
    this.dialogRef.close();
  }

  salirApp(): void {
    this.store.dispatch(LoginAction.clean());
    this.route.navigate([Rutas.LOGIN]);
    this.closeModal();
  }
}
