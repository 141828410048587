import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as LoginAction from '../../ngrx/actions/login.action';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Rutas } from '../../ultil/rutas';
import { ModalCambiarPasswordComponent } from '../modal-cambiar-password/modal-cambiar-password.component';
import * as opcionesModal from '../../ultil/opcionesModal';
import * as MenuAction from 'src/app/modules/_global/ngrx/actions/menu.action';

@Component({
  selector: 'app-menu-usuario',
  templateUrl: './menu-usuario.component.html',
  styleUrls: ['./menu-usuario.component.scss']
})
export class MenuUsuarioComponent implements OnInit, OnDestroy {

  usuarioSubscription = new Subscription();
  empresaSubscription = new Subscription();
  usuario: any;
  empresaSelected: any;

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    this.validShowNombreUsuario();
  }

  showNombreUsuario = true;

  constructor(
    private store: Store<any>,
    private route: Router,
    public dialog: MatDialog
  ) {

  }

  ngOnInit() {
    this.store.dispatch(MenuAction.getUsuario());
    this.validShowNombreUsuario();
    this.usuarioSubscription = this.store.select((state) => state?.menu?.Usuario).subscribe(Usuario => {
      if (Usuario != undefined){
        this.usuario = {...Usuario};
        this.setUsuario();
      }
    });
    this.empresaSubscription = this.store.select((state) => state?.menu?.EmpresaSelected).subscribe(EmpresaSelected => {
      if (EmpresaSelected != undefined){
        this.empresaSelected = {...EmpresaSelected};
      }
    });
  }

  ngOnDestroy() {
    this.usuarioSubscription.unsubscribe();
    this.empresaSubscription.unsubscribe();
    this.dialog.ngOnDestroy();
  }

  validShowNombreUsuario(){
    const widthWindows = window.innerWidth;
    if(widthWindows >= 800){
      this.showNombreUsuario = true;
    }else{
      this.showNombreUsuario = false;
    }
  }

  setUsuario(){
    if(this.usuario != undefined && this.usuario != null){
      this.setCambiarPassword();
    }
  }

  setCambiarPassword(){
    try {
      if(this.usuario != undefined && this.usuario != null){
        if(this.usuario.cambiarPassword != undefined && this.usuario.cambiarPassword != null){
          if(this.usuario.cambiarPassword === false){
            //this.store.dispatch(LoginAction.setCambiarPassword({cambiarPassword: this.usuario.cambiarPassword}));
          }
        }
      }
    } catch (error) {
      console.error('Error MenuUsuarioComponent -> validateCambiarPassword()', error);
    }
  }

  showModalCambiarPassword() {
    const dialogRef = this.dialog.open(ModalCambiarPasswordComponent, {
      disableClose: true,
      autoFocus: true,
      closeOnNavigation: false,
      position: { top: '100px' },
      width: opcionesModal.TAMANIO_MODAL.SM
    });
    //this.store.dispatch(LoginAction.setCambiarPassword({cambiarPassword: false}));
  }

  salirApp(): void {
    this.store.dispatch(LoginAction.clean());
    this.route.navigate([Rutas.LOGIN]);
  }

}
