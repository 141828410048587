<div class="app-reportes-form">
  <div mat-dialog-title class="titulo-dialog">
    <button [mat-dialog-close]="true" class="close" mat-icon-button>
      <em class="icon ni ni-cross-sm"></em>
    </button>
    <div class="titulo">
      <span class="icono"><mat-icon>assignment_late</mat-icon></span>
      <span class="titulo">Agregar alguna Observación</span>
    </div>
  </div>
  <mat-dialog-content>
    <form>
      <div class="row mb-3">
        <div class="col-12 mb-2">
          <strong>Empresa:</strong> {{ empresaSelected?.nombre }}
        </div>
        <div class="col-12 mb-2">
          <strong>Reporte:</strong> {{ data?.item?.nombre }}
        </div>
        <div class="col-12">
          <mat-form-field>
            <mat-label>Por favor ingresa la observación al reporte: </mat-label>
            <input
              matInput
              [formControl]="rutaControl"
              required
              appSelectInFocus
            />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button class="btn-primary" (click)="guardarFormulario()">
      Guardar
    </button>
    <button mat-raised-button mat-dialog-close class="btn-danger">
      Cancelar
    </button>
  </mat-dialog-actions>
</div>
